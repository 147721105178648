<template>
  <div>
    <v-dialog v-model="dialog" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-btn
          small
          class="mx-2 my-1 white--text text-capitalize"
          v-on="on"
          tile
          color="#92E84E"
          @click="onShowDialog"
          >Edit</v-btn
        >
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 black--text">Edit Package</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-container v-if="packagesInfo">
          <!-- {{ packages }} -->
          <v-form class="" v-model="sectionOneValid" ref="formOne">
            <div v-show="section === 1" class="pa-sm-8">
              <v-layout wrap class="mb-5">
                <h4 class="font-weight-bold text-body-1 black--text">
                  1. Basic information
                </h4>
                <v-spacer></v-spacer>
              </v-layout>
              <v-layout wrap class="mt-6 mt-sm-0">
                <v-flex xs12 sm3 md2>
                  <h4 class="black--text text-body-2">Plan Name</h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-text-field
                    label="Plan Name"
                    v-model="packages.titleEn"
                    :rules="[requiredValidator('plan name')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md2 class="ms-md-16">
                  <h4 class="black--text text-body-2">Show Order</h4>
                </v-flex>
                <v-flex xs12 sm8 md2 class="mt-3 mt-md-0">
                  <v-text-field
                    type="number"
                    v-model="packages.showOrder"
                    @keypress="isNumber"
                    placeholder="Order"
                    min="0"
                    :rules="[requiredValidator('order')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="black--text text-body-2">Plan Name Arabic</h4>
                </v-flex>

                <v-flex xs12 sm8 md4 class="mt-3 mt-md-0">
                  <v-text-field
                    v-model="packages.titleAr"
                    label="Plan Name Arabic"
                    :rules="[requiredValidator('plan name in arabic')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>

                <!-- <v-flex xs12 sm3 md2> </v-flex>
              <v-flex xs12 sm8 md4 class="mt-3 mt-md-0">
                <v-checkbox
                  label="Celebrity Package"
                  v-model="packages.isCelebrityPackage"
                ></v-checkbox>
              </v-flex> -->
              </v-layout>
              <!-- <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="text-body-2 black--text">Image</h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-img
                    v-if="!isChangable"
                    class="white--text align-end"
                    :width="200"
                    :height="130"
                    :src="imageUrl + packages.image"
                  >
                    <v-btn v-if="!isChangable" small @click="changeImage" tile>
                      <v-icon dark>mdi-pencil</v-icon>
                    </v-btn>
                  </v-img>
                  <v-flex xs12 md2 v-if="isChangable">
                    <h4 class="text-body-2 black--text">Select Image</h4>
                  </v-flex>
                  <input
                    v-if="isChangable"
                    type="file"
                    accept="image/*"
                    @change="onFileSelected($event)"
                  />
                </v-flex>
                <v-snackbar v-model="snackbar" :timeout="3000" :color="snackColor">{{
                  snackText
                }}</v-snackbar>
              </v-layout> -->

              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text">Image (English)</h5>
                </v-flex>
                <v-flex xs12 sm3 md1 class="">
                  <v-img :width="150" class="ms-md-4" :src="getImageEn()" />
                </v-flex>
                <v-flex xs12 sm5 md6 class="ms-md-6">
                  <croppa
                    class="ml-2 mt-2"
                    v-model="myCroppaEn"
                    :width="170"
                    :height="100"
                    canvas-color="black"
                    :placeholder="'Update image'"
                    :placeholder-font-size="16"
                    placeholder-color="yellow"
                    :accept="'image/*'"
                    :file-size-limit="0"
                    :quality="5"
                    :zoom-speed="3"
                    :disabled="false"
                    :disable-drag-and-drop="false"
                    :disable-click-to-choose="false"
                    :disable-drag-to-move="false"
                    :disable-scroll-to-zoom="false"
                    :disable-rotation="false"
                    :prevent-white-space="false"
                    :reverse-scroll-to-zoom="false"
                    :show-remove-button="true"
                    :remove-button-color="'red'"
                    :remove-button-size="20"
                  >
                    /></croppa
                  >
                </v-flex>
                <v-snackbar
                  v-model="snack"
                  :timeout="3000"
                  :color="snackColor"
                  >{{ snackText }}</v-snackbar
                >
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text">Image (Arabic)</h5>
                </v-flex>
                <v-flex xs12 sm3 md1 class="">
                  <v-img :width="150" class="ms-md-4" :src="getImageAr()" />
                </v-flex>
                <v-flex xs12 sm5 md6 class="ms-md-6">
                  <croppa
                    class="ml-2 mt-2"
                    v-model="myCroppaAr"
                    :width="170"
                    :height="100"
                    canvas-color="black"
                    :placeholder="'Update image'"
                    :placeholder-font-size="16"
                    placeholder-color="yellow"
                    :accept="'image/*'"
                    :file-size-limit="0"
                    :quality="5"
                    :zoom-speed="3"
                    :disabled="false"
                    :disable-drag-and-drop="false"
                    :disable-click-to-choose="false"
                    :disable-drag-to-move="false"
                    :disable-scroll-to-zoom="false"
                    :disable-rotation="false"
                    :prevent-white-space="false"
                    :reverse-scroll-to-zoom="false"
                    :show-remove-button="true"
                    :remove-button-color="'red'"
                    :remove-button-size="20"
                  >
                    /></croppa
                  >
                  <v-layout>
                    <span style="color: red" class="text-center">
                      ****Use mouse scroll wheel to crop image****
                    </span>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-layout wrap class="mt-6 mt-sm-3">
                <v-flex xs4 sm3 md2>
                  <h4 class="text-body-2 black--text">Active</h4>
                </v-flex>
                <v-flex xs4 sm4 md4>
                  <v-checkbox v-model="packages.active" class="mt-n1">
                  </v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-2">
                <v-flex xs12 sm3 md2>
                  <h4 class="text-body-2 black--text">Description</h4>
                </v-flex>
                <v-flex xs12 sm8 md4 class="">
                  <v-textarea
                    solo
                    name="input-7-4"
                    value
                    label="Description"
                    v-model="packages.descriptionEn"
                    :rules="[requiredValidator('description ')]"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md4 offset-sm-3 offset-md-0 class="ms-md-16">
                  <v-textarea
                    label="Description Arabic"
                    solo
                    name="input-7-4"
                    value
                    v-model="packages.descriptionAr"
                    :rules="[requiredValidator('description in arabic')]"
                  ></v-textarea>
                </v-flex>
              </v-layout>

              <v-layout class="mt-6" wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="text-body-2 black--text">Meal Info</h4>
                </v-flex>
                <v-flex xs6 sm4 md4 class="mt-5 mt-md-0">
                  <v-layout wrap>
                    <v-flex xs6 sm4 md4 class="text-body-2 black--text"
                      >Protien</v-flex
                    >
                    <v-flex xs6 sm5 md8>
                      <v-text-field
                        solo
                        label="Protein"
                        @keypress="isNumber"
                        value=""
                        dense
                        type="number"
                        v-model="packages.meanInfo.protein"
                        :rules="[requiredValidator('protein')]"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs5 sm4 md4 class="ms-4 ms-sm-0 ms-md-16 mt-5 mt-md-0">
                  <v-layout wrap>
                    <v-flex xs6 sm4 md4 class="text-body-2 black--text"
                      >Carbs</v-flex
                    >
                    <v-flex xs6 sm5 md8>
                      <v-text-field
                        solo
                        label="Carbs"
                        dense
                        @keypress="isNumber"
                        value=""
                        type="number"
                        v-model="packages.meanInfo.carbs"
                        :rules="[requiredValidator('carbs')]"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout class="mt-6" wrap v-if="packages.calorieRange">
                <v-flex xs12 sm3 md2>
                  <h4 class="text-body-2 black--text">Calorie Range</h4>
                </v-flex>
                <v-flex xs6 sm4 md4 class="mt-5 mt-sm-0">
                  <v-layout wrap>
                    <v-flex xs6 sm4 md4 class="text-body-2 black--text"
                      >From</v-flex
                    >
                    <v-flex xs6 sm5 md8>
                      <v-text-field
                        solo
                        label="From Calorie Range"
                        value=""
                        @keypress="isNumber"
                        dense
                        min="0"
                        type="number"
                        v-model="packages.calorieRange.from"
                        :rules="[requiredValidator('from calorie range')]"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs5 sm4 md4 class="ms-4 ms-sm-0 ms-md-16 mt-5 mt-sm-0">
                  <v-layout wrap>
                    <v-flex xs6 sm4 md4 class="text-body-2 black--text"
                      >To</v-flex
                    >
                    <v-flex xs6 sm5 md8>
                      <v-text-field
                        solo
                        label="To Calorie Range"
                        dense
                        @keypress="isNumber"
                        min="0"
                        value=""
                        type="number"
                        v-model="packages.calorieRange.to"
                        :rules="[requiredValidator('to calorie range')]"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="text-body-2 black--text">Diet plan</h4>
                </v-flex>
                <v-flex xs12 sm8 md10 class="mt-n3">
                  <v-radio-group v-model="dietPlan" :mandatory="false" row>
                    <v-row>
                      <v-col
                        cols="6"
                        sm="6"
                        lg="4"
                        v-for="item in allDietPlans"
                        :key="item._id"
                      >
                        <v-radio
                          :label="item.nameEn"
                          :value="item._id"
                        ></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-flex>
              </v-layout>

              <v-layout wrap justify-start>
                <v-flex xs12 sm3 md2 class="mt-n2 mt-sm-2">
                  <h4 class="black--text text-body-2">Start Date</h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        solo
                        :value="formatDate"
                        dense
                        append-icon="fa-calendar-alt black--text"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="secondary"
                      :min="new Date() | moment('YYYY-MM-DD')"
                      v-model="startDate"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-row class="my-2">
                <v-checkbox
                  v-model="allowWeekDayCustomization"
                  label="Allow Week Day customization"
                  messages="(You will receive meals only on  selected days)"
                ></v-checkbox>
                <v-row v-if="allowWeekDayCustomization" class="mt-3">
                  <v-col
                    cols="3"
                    v-for="(day, index) in allowedWeekDays"
                    :key="index"
                  >
                    <v-checkbox
                      class="d-inline"
                      v-model="day.value"
                      :label="day.day"
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-row>

              <div class="d-flex justify-end">
                <v-btn
                  :disabled="!sectionOneValid"
                  @click="section = 2"
                  class="primary"
                  >Next</v-btn
                >
              </div>
            </div>
          </v-form>
          <div v-if="packages.availableChoices">
            <v-form class="" v-model="sectionTwoValid" ref="formTwo">
              <div v-show="section === 2">
                <v-layout class="mt-4" wrap>
                  <h4 class="font-weight-bold text-body-1 black--text">
                    2. Days Choices
                  </h4>
                  <v-spacer></v-spacer>
                  <PackageChoicesForm
                    :choice="choice"
                    :availableChoices="packages.availableChoices"
                    :allowedWeekDays="allowedWeekDays"
                  />
                </v-layout>

                <PackageChoiceTable
                  class="mt-2"
                  :availableChoices="packages.availableChoices"
                  :allCategories="allCategories"
                  :allowedWeekDays="allowedWeekDays"
                ></PackageChoiceTable>
                <v-system-bar height="1"></v-system-bar>
                <div class="d-flex justify-space-between mt-3">
                  <v-btn @click="section = 1">Back</v-btn>
                  <v-btn
                    :disabled="!packages.availableChoices.length"
                    @click="section = 3"
                    class="primary"
                    >Next</v-btn
                  >
                </div>
              </div>
            </v-form>
          </div>
          <v-form class="" v-model="sectionThreeValid" ref="formThree">
            <div v-show="section === 3">
              <v-row class="ms-1 ms-sm-0">
                <v-tabs
                  class="mt-12"
                  slider-color="black"
                  v-model="menuCategoryIndex"
                >
                  <v-tab
                    v-for="category in allCategories.filter(x => x.hasMeal)"
                    :key="category._id"
                    :value="category._id"
                    v-model="category._id"
                    ><label
                      class="text-caption text-sm-body-2 black--text text-capitalize"
                      >{{ category.nameEn }}
                    </label></v-tab
                  >
                </v-tabs>
              </v-row>
              <div
                v-for="(category, index) in allCategories.filter(
                  x => x.hasMeal
                )"
                :key="category._id"
              >
                <PackageMeals
                  v-show="menuCategoryIndex == index"
                  :category="category"
                  :dietPlan="dietPlan"
                  :defaultMenu="getDefaultMenu(category)"
                  :packageMenu="getPackageMeals(category)"
                  :noOfPackageWeek="noOfPackageWeek"
                  @updateCategory="updateCategory"
                  :allowedWeekDays="allowedWeekDays"
                  :packageMenuWeek="packageMenuWeek"
                  :customNutrient="getNutrients(category)"
                />
              </div>

              <v-layout justify-space-between class="mt-8">
                <v-btn @click="section = 2">Back</v-btn>
                <v-btn
                  class="white--text text-capitalize"
                  color="secondary"
                  @click="updatePackage()"
                  :loading="btnloading"
                  :disabled="!sectionThreeValid"
                  >Update Package</v-btn
                >
              </v-layout>
            </div>
          </v-form>
        </v-container>
        <v-container v-else>
          <v-row>
            <v-col cols="6"> </v-col>
            <v-col cols="5">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snack" right top :timeout="3000" :color="snackColor">
      {{ text }}
    </v-snackbar>
  </div>
</template>
<script>
import PackageChoiceTable from "../components/PackageChoiceTable.vue";
import PackageChoicesForm from "../components/PackageChoicesForm";
// import PackageMenuTable from "../components/PackageMenuTable.vue";
import {
  requiredValidator,
  requiredValidatorNumber
} from "../../core/methods/validations";
import PackageMeals from "../components/PackageMeals.vue";
import moment from "moment";
import { DATE_FORMAT } from "../../utils/utils";
export default {
  components: {
    PackageChoiceTable: PackageChoiceTable,
    PackageMeals,
    PackageChoicesForm
  },
  props: {
    package_id: {
      required: true
    }
  },
  created() {
    this.getAllCategories();
  },

  apollo: {
    getAllDietPlans() {
      return {
        query: require("../diet-plan/api/getAllDietPlans.graphql"),
        result({ data, loading }) {
          this.loading = loading;
          this.allDietPlans = data.getAllDietPlans.filter(x => x.active);
        }
      };
    },
    getAppConfigurations() {
      return {
        query: require("../../settings/api/getAppConfigurations.graphql"),
        result({ data }) {
          let packageCycleDay = data.getAppConfigurations.packageCycleDay;

          if (packageCycleDay) {
            this.noOfPackageWeek = packageCycleDay / 7;
          } else {
            this.noOfPackageWeek = 1;
          }
        }
      };
    }
  },
  data() {
    return {
      noOfPackageWeek: 1,
      allowWeekDayCustomization: false,
      allowedWeekDays: [
        { day: "Sunday", value: true },
        { day: "Monday", value: true },
        { day: "Tuesday", value: true },
        { day: "Wednesday", value: true },
        { day: "Thursday", value: true },
        { day: "Friday", value: true },
        { day: "Saturday", value: true }
      ],
      menu2: false,
      startDate: "",
      snack1: false,
      isKetoDiet: false,
      snack: false,
      snackColor: "",
      snackText: "",
      allMeals: [],
      menuCategoryIndex: null,
      requiredValidatorNumber,
      requiredValidator,
      isChangableEn: false,
      isChangableAr: false,
      imageUrl: process.env.VUE_APP_BASE_URL,
      dialog: false,
      choice: {
        choiceNameEn: null,
        choiceNameAr: null,
        price: null,
        oldPrice: null,
        availableDays: [],
        availableNumOfDays: null
      },
      allCategories: [],
      btnloading: false,
      valid: true,
      errors: [],
      packages: [],
      snackbar: false,
      text: "",
      variables: {
        limit: 25,
        skip: 0
      },
      listOfDays: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      allDietPlans: [],
      dietPlan: "",
      customNutrients: [],
      packagesInfo: null,
      section: 1,
      imageAr: null,
      image: null,
      sectionOneValid: true,
      sectionTwoValid: true,
      sectionThreeValid: true,
      packageMenuWeek: [],
      myCroppaEn: null,
      myCroppaAr: null
    };
  },
  computed: {
    formatDate() {
      return this.startDate ? moment(this.startDate).format(DATE_FORMAT) : "";
    }
  },

  watch: {
    allowWeekDayCustomization: {
      handler() {
        if (!this.allowWeekDayCustomization) {
          this.allowedWeekDays = this.allowedWeekDays.map(item => {
            return { ...item, value: true };
          });
        }
      }
    },
    packages: {
      handler() {
        this.packageMenuWeek = [];
        this.allCategories.forEach(category => {
          let hasMeal = false;
          let choices = this.packages.availableChoices;

          choices.forEach(choice => {
            if (choice.mealChoice) {
              choice.mealChoice.forEach(mealChoice => {
                mealChoice.dayChoices.forEach(dayChoice => {
                  if (dayChoice.mealCount) {
                    let containCategory = false;

                    let isExisting = dayChoice.mealCount.some(x => x.category);
                    if (isExisting) {
                      containCategory = dayChoice.mealCount.find(
                        x => x.category._id == category._id
                      );
                    } else {
                      containCategory = dayChoice.mealCount.find(
                        x => x._id == category._id
                      );
                    }

                    if (containCategory) {
                      if (parseInt(containCategory.count) > 0) {
                        hasMeal = true;
                      }
                    }
                  }
                  // if (dayChoice.allCategories) {
                  //   let containCategory = dayChoice.allCategories.find(
                  //     (x) => x._id == category._id
                  //   );
                  //   if (containCategory) {
                  //     if (parseInt(containCategory.count) > 0) {
                  //       hasMeal = true;
                  //     }
                  //   }
                  // }
                });
              });
            }
          });

          this.allowedWeekDays.map(item => {
            let index = category.daysItem.findIndex(
              day => day.weekDay === item.day
            );
            if (item.value === false && index >= 0) {
              category.daysItem.splice(index, 1);
            } else if (item.value === true && index === -1) {
              category.daysItem.push({
                weekDay: item.day,
                menuItem: ""
              });
            }
          });
          category.hasMeal = hasMeal;
        });
      },
      deep: true
    },
    dialog: {
      handler() {
        this.section = 1;
      }
    }
  },

  methods: {
    getImageEn() {
      if (this.packages.image) return this.imageUrl + this.packages.image;
      else return require("../../assets/no-image.png");
    },
    getImageAr() {
      if (this.packages.imageAr) return this.imageUrl + this.packages.imageAr;
      else return require("../../assets/no-image.png");
    },
    setAllowedWeeks() {
      if (this.packagesInfo.allowedWeekDays.length) {
        this.allowWeekDayCustomization = true;
        this.allowedWeekDays.map(item => {
          item.value = false;
        });
        this.packagesInfo.allowedWeekDays.map(day => {
          let index = this.allowedWeekDays.findIndex(item => item.day === day);
          this.allowedWeekDays[index].value = true;
        });
      }
    },
    getPackageById() {
      this.$apollo
        .watchQuery({
          query: require("../api/getPackageById.graphql"),
          variables: {
            packageId: this.package_id
          }
        })
        .refetch({
          variables: {
            packageId: this.package_id
          }
        })
        .then(data => {
          this.packagesInfo = data.data.getPackageById;
          if (!this.packagesInfo.calorieRange) {
            this.packagesInfo.calorieRange = { from: "", to: "" };
          }
          this.setAllowedWeeks();
          this.dietPlan = this.packagesInfo.dietPlan
            ? this.packagesInfo.dietPlan._id
            : "";
          let packages = JSON.parse(JSON.stringify(this.packagesInfo));
          packages.availableChoices.forEach(choice => {
            if (choice.mealChoice) {
              choice.mealChoice.forEach(mealChoice => {
                if (mealChoice.mealCount) {
                  mealChoice.mealCount.forEach(mealCount => {
                    if (mealCount.category) {
                      mealCount.categoryNameEn = mealCount.category.nameEn;
                      mealCount.category = mealCount.category._id;
                    }
                  });
                }
              });
            }
          });
          this.packages = packages;
          this.packages.startDate
            ? (this.startDate = moment(
                new Date(parseInt(this.packages.startDate))
              ).format("YYYY-MM-DD"))
            : (this.startDate = "");
        });
    },

    getAllCategories() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("@/menu-packages/api/getAllCategories.graphql"),
          variables: { limit: 0, skip: 0 }
        })
        .refetch({
          variables: { limit: 0, skip: 0 }
        })
        .then(data => {
          this.loading = false;

          let allCategories = JSON.parse(
            JSON.stringify(data.data.getAllCategories.category)
          );

          allCategories = JSON.parse(
            JSON.stringify(allCategories.filter(x => x.active))
          );
          let daysItem = [];
          this.listOfDays.forEach(day => {
            daysItem.push({
              weekDay: day,
              menuItem: ""
            });
          });

          allCategories.forEach(element => {
            element.mealCount = 0;
            element.daysItem = JSON.parse(JSON.stringify(daysItem));
            let packageMenuWeek = [];
            for (let index = 0; index < this.noOfPackageWeek; index++) {
              let weekCategories = {
                categoryInfo: {
                  daysItem: daysItem
                },
                count: index
              };
              packageMenuWeek.push(weekCategories);
            }
            element.packageMenuWeek = packageMenuWeek;
            if (this.packages.mealsCount) {
              try {
                // console.log(this.packages.mealsCount);
                let mealsCount = this.packages.mealsCount.filter(
                  x =>
                    x.menuCategory != null && x.menuCategory._id == element._id
                );
                if (mealsCount.length > 0) {
                  element.mealCount = mealsCount[0].count;
                }
              } catch (error) {
                console.log(error);
              }
            }
          });

          this.allCategories = JSON.parse(JSON.stringify(allCategories));
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    onChangeCount() {
      let index = 0;
      this.allCategories.forEach(element => {
        if (element.mealCount > 0) {
          this.menuCategoryIndex = index;
          return false;
        }
        index = index + 1;
      });
    },
    onShowDialog() {
      this.dialog = true;
      this.getPackageById();
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getDefaultMenu(category) {
      if (this.packages.defaultMenu.length > 0) {
        let defaultMenu = this.packages.defaultMenu.filter(
          x => x.menuCategory != null && x.menuCategory._id == category._id
        );
        return defaultMenu;
      }
      return null;
    },

    getNutrients(category) {
      if (this.packages.customNutrients) {
        this.customNutrients = this.packages.customNutrients;
      }
      let newNutrients = new Object();
      let data = this.customNutrients.find(x => x.menuCategory == category._id);
      if (!data) {
        newNutrients.menuCategory = category._id;
        newNutrients.protein = "1";
        newNutrients.fat = "1";
        newNutrients.carbs = "1";
        newNutrients.calories = "1";
        this.customNutrients.push(newNutrients);
      }
      return this.customNutrients;
    },
    getPackageMeals(category) {
      if (this.packages.packageMenu) {
        if (this.packages.packageMenu.length > 0) {
          let packageMenu = this.packages.packageMenu.filter(
            x => x.menuCategory != null && x.menuCategory._id == category._id
          );
          return packageMenu;
        }
      }
      return null;
    },
    updateCategory(category) {
      let allCategories = this.allCategories;
      var foundIndex = allCategories.findIndex(x => x._id == category._id);
      allCategories[foundIndex] = category;
      this.allCategories = allCategories;
      // console.log(this.allCategories)
    },

    onFileSelected(event) {
      const selectedFile = event.target.files[0];
      // console.log(selectedFile);
      this.packages.image = selectedFile;
    },

    updatePackage() {
      const croppaEnPromise = new Promise(resolve => {
        if (this.myCroppaEn.hasImage()) {
          this.myCroppaEn.generateBlob(blob => {
            this.image = blob;
            resolve();
          });
        } else {
          resolve();
        }
      });

      const croppaArPromise = new Promise(resolve => {
        if (this.myCroppaAr.hasImage()) {
          this.myCroppaAr.generateBlob(blob => {
            this.imageAr = blob;
            resolve();
          });
        } else {
          resolve();
        }
      });

      Promise.all([croppaEnPromise, croppaArPromise]).then(() => {
        this.updatePackageAPI();
      });
    },

    updatePackageAPI() {
      if (!this.packages.availableChoices.length) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Please add a choice";
        return;
      }

      // if (this.packages.availableChoices != null) {
      //   this.packages.availableChoices.map(function (elt) {
      //     delete elt.__typename;
      //   });
      // }

      let availableChoices = new Array();
      this.packages.availableChoices.forEach(element => {
        let availableDays = [];
        if (element.availableDays[0].day) {
          availableDays = element.availableDays.flatMap(x => x.day);
        } else {
          availableDays = element.availableDays;
        }

        let mealChoices = new Array();
        element.mealChoice.forEach(mealChoice => {
          let dayChoices = new Array();
          mealChoice.dayChoices.forEach(dayChoice => {
            let mealCounts = new Array();
            dayChoice.mealCount.forEach(element => {
              if (parseInt(element.count) > 0) {
                let mealCount = {
                  count: parseInt(element.count),
                  category: element.category
                    ? element.category._id
                    : element._id
                };
                mealCounts.push(mealCount);
              }
            });

            let dayChoiceItem = {
              day: dayChoice.day,
              mealCount: mealCounts
            };
            dayChoices.push(dayChoiceItem);
          });

          mealChoices.push({
            price: parseFloat(mealChoice.price),
            strikeThroughPrice: parseFloat(mealChoice.strikeThroughPrice),
            variantNameEn: mealChoice.variantNameEn,
            variantNameAr: mealChoice.variantNameAr,
            dayChoices: dayChoices
          });
          // let mealCounts = new Array();
          // mealChoice.mealCount.forEach((mealCount) => {
          //   mealCounts.push({
          //     category: mealCount.category,
          //     count: parseInt(mealCount.count),
          //   });
          // });
          // mealChoices.push({
          //   mealCount: mealCounts,
          //   price: parseFloat(mealChoice.price),
          // });
        });

        let avail = {
          availableDays: availableDays,
          availableNumOfDays: element.availableNumOfDays,
          choiceNameAr: element.choiceNameAr,
          choiceNameEn: element.choiceNameEn,
          mealChoice: mealChoices,
          _id: element._id
        };
        availableChoices.push(avail);
      });

      let meanInfo = {
        protein: this.packages.meanInfo.protein.toString(),
        carbs: this.packages.meanInfo.carbs.toString()
      };

      let calorieRange = {
        from: parseInt(this.packages.calorieRange.from),
        to: parseInt(this.packages.calorieRange.to)
      };
      debugger;
      //let defaultMenuInputs = new Array();
      //let packageMenuInputs = new Array();

      let mealsCount = new Array();

      let packageMenus = new Array();
      let packageDefaultMenus = new Array();

      this.allCategories.forEach(category => {
        if (category.hasMeal) {
          category.packageMenuWeek.forEach(packageMenuWeek => {
            let daysItems = new Array();
            let daysDefaultItems = new Array();

            packageMenuWeek.categoryInfo.daysItem.forEach(item => {
              let daysItem = {
                weekDay: item.weekDay,
                menuItem: item.menuItem ? item.menuItem.map(x => x._id) : []
              };
              daysItems.push(daysItem);
            });

            packageMenuWeek.categoryInfo.daysItem.forEach(item => {
              let defaultMenuItems = new Array();
              if (item.defaultMenu) {
                item.defaultMenu.forEach(element => {
                  if (element._id) {
                    let isExisting = item.menuItem.find(
                      x => x._id == element._id
                    );
                    if (isExisting) {
                      defaultMenuItems.push(element._id);
                    }
                  } else {
                    let isExisting = item.menuItem.find(x => x._id == element);
                    if (isExisting) {
                      defaultMenuItems.push(element);
                    }
                  }
                });
              }
              let daysItem = {
                weekDay: item.weekDay,
                menuItem: defaultMenuItems
              };
              daysDefaultItems.push(JSON.parse(JSON.stringify(daysItem)));
            });

            let weekMenu = {
              weekNo: packageMenuWeek.count + 1,
              daysItem: daysItems,
              menuCategory: category._id
            };
            let weekDefaultMenu = {
              weekNo: packageMenuWeek.count + 1,
              daysItem: daysDefaultItems,
              menuCategory: category._id
            };
            packageMenus.push(weekMenu);
            packageDefaultMenus.push(weekDefaultMenu);
          });
        }
      });

      // this.allCategories.forEach((category) => {

      //   if (category.hasMeal) {
      //     let defaultDaysItem = JSON.parse(JSON.stringify(category.daysItem));
      //     let packageMenuDaysItem = JSON.parse(
      //       JSON.stringify(category.daysItem)
      //     );

      //     defaultDaysItem.forEach((item) => {
      //       let allMenuItems = new Array();
      //       if (item.defaultMenu) {
      //         item.defaultMenu.forEach((element) => {
      //           if (item.menuItem) {
      //             let menuIds = item.menuItem.find((x) => x._id == element);
      //             if (menuIds) {
      //               allMenuItems.push(element);
      //             }
      //           }
      //         });
      //       }
      //       item.menuItem = allMenuItems;
      //       delete item.defaultMenu;
      //     });

      //     packageMenuDaysItem.forEach((item) => {
      //       delete item.defaultMenu;
      //       item.menuItem = item.menuItem.map((x) => x._id);
      //     });

      //     let defaultMenu = {
      //       menuCategory: category._id,
      //       daysItem: defaultDaysItem,
      //     };

      //     let packageMenu = {
      //       menuCategory: category._id,
      //       daysItem: packageMenuDaysItem,
      //     };

      //     defaultMenuInputs.push(defaultMenu);
      //     packageMenuInputs.push(packageMenu);
      //   }
      //   let mealCount = {
      //     count: category.count,
      //     menuCategory: category._id,
      //   };
      //   mealsCount.push(mealCount);
      // });

      this.packages.customNutrients = this.customNutrients;
      this.packages.customNutrients.forEach(x => delete x.__typename);
      this.btnloading = true;
      if (typeof this.packages.image === "string") this.packages.image = null;
      let allowedWeekDays;
      if (this.allowWeekDayCustomization) {
        allowedWeekDays = this.allowedWeekDays
          .filter(item => item.value)
          .map(day => day.day);
      } else {
        allowedWeekDays = [];
      }

      this.$apollo
        .mutate({
          mutation: require("@/menu-packages/api/updatePackages.graphql"),
          variables: {
            id: this.packages._id,
            titleEn: this.packages.titleEn,
            titleAr: this.packages.titleAr,
            descriptionEn: this.packages.descriptionEn,
            descriptionAr: this.packages.descriptionAr,
            image: this.image,
            imageAr: this.imageAr,
            active: this.packages.active,
            isCelebrityPackage: false,
            showOrder: parseInt(this.packages.showOrder),
            meanInfo: meanInfo,
            mealsCount: mealsCount,
            defaultMenu: packageDefaultMenus,
            packageMenu: packageMenus,
            availableChoices: availableChoices,
            planType: this.isKetoDiet ? "KETO" : "NORMAL",
            dietPlan: this.dietPlan,
            customNutrients: this.packages.customNutrients,
            startDate: this.startDate,
            allowedWeekDays: allowedWeekDays,
            calorieRange: calorieRange
          },
          update: () => {
            // this.$refs.form.reset();
            this.btnloading = false;
            this.isChangableEn = false;
            this.isChangableAr = false;
            this.snack = true;
            this.snackColor = "success";
            this.text = "Package Updated";
            this.dialog = false;
            this.$root.$emit("updatePackage");
          }
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
    close() {
      this.isChangableEn = false;
      this.isChangableAr = false;
      this.dialog = false;
    }
  }
};
</script>
<style>
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  color: black;
}
</style>
