<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="550px">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" class="white--text text-capitalize font-weight-bold" color="primary">
          <v-icon small class="me-1">mdi-plus</v-icon> Add
        </v-btn>
      </template>

      <v-card class="px-4 py-6 py-sm-8" color="pop_bg">
        <v-row>
          <v-card-title class="black--text text-h6 mt-n2 ms-2">Add Vendors</v-card-title>

          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-divider></v-divider>

        <v-card-text class="pa-0 pt-2">
          <v-container class="mt-2">
            <v-form @submit="createVendor()" v-model="valid" ref="form">
              <v-layout wrap>
                <v-flex xs12 sm5>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">
                    Company Name English
                  </h4>
                </v-flex>
                <v-flex xs12 sm7 md7>
                  <v-text-field v-model="companyNameEn" :rules="[requiredValidator('company')]" solo
                    placeholder="Company Name English" dense></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm5>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">
                    Company Name Arabic
                  </h4>
                </v-flex>
                <v-flex xs12 sm7 md7>
                  <v-text-field v-model="companyNameAr" :rules="[requiredValidator('company')]" solo
                    placeholder="Company Name Arabic" dense></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm5>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">Phone</h4>
                </v-flex>
                <v-flex xs12 sm7 md7>
                  <v-text-field v-model="phone" :rules="[requiredValidator('phone'), minLengthValidator('phone', 8),
                  maxLengthValidator('phone', 8),]" solo placeholder="Phone" dense></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm5>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">
                    Contact Person Name
                  </h4>
                </v-flex>
                <v-flex xs12 sm7 md7>
                  <v-text-field v-model="contactPersonName" solo placeholder="Contact Person Name" dense></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm5>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">
                    Contact Person Phone
                  </h4>
                </v-flex>
                <v-flex xs12 sm7 md7>
                  <v-text-field v-model="contactPersonPhone" solo type="number" placeholder="Contact Person Phone"
                    dense></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm5>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">
                    Contact Person Type
                  </h4>
                </v-flex>
                <v-flex xs12 sm7 md7>
                  <v-select :items="contactPersonTypeList" v-model="contactPersonType" solo label="Select Type" dense
                    attach></v-select>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm5>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">Brands</h4>
                </v-flex>
                <v-flex xs12 sm7 md7>
                  <v-select :items="brandList" v-model="brands" item-text="nameEn" item-value="_id"
                    label="Select Brands" dense attach multiple solo></v-select>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm5>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">Area</h4>
                </v-flex>
                <v-flex xs12 sm7 md7>
                  <v-select v-model="area" :items="areasList" item-text="nameEn" item-value="_id" solo label="Area"
                    @change="getBlocksByArea(area)" dense></v-select>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm5>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">Block</h4>
                </v-flex>
                <v-flex xs12 sm7 md7>
                  <v-select v-model="block" :items="blocksByArea" item-text="block" item-value="_id" solo label="Block"
                    dense></v-select>
                </v-flex>
              </v-layout>

              <v-layout row justify-end class="mt-2 me-1">
                <v-btn :width="$vuetify.breakpoint.smAndUp ? 120 : 90" color="primary"
                  class="white--text text-capitalize" @click="createVendor()" :loading="btnloading" :disabled="!valid">
                  Save</v-btn>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  requiredValidator, minLengthValidator,
  maxLengthValidator,
} from "../../core/methods/validations";
export default {
  components: {},
  created() {
    this.getBrandList();
    this.getAllAreas();
  },

  data() {
    return {
      requiredValidator: requiredValidator,
      minLengthValidator,
      maxLengthValidator,
      btnloading: false,
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      companyNameEn: "",
      companyNameAr: "",
      phone: "",
      contactPersonName: "",
      contactPersonPhone: "",
      contactPersonType: "",
      brands: "",
      area: "",
      block: "",
      jedha: "",
      street: "",
      brandList: [],
      show_dialog_box: false,
      contactPersonTypeList: ["OWNER", "MANAGER", "SALESMAN"],
      areasList: [],
      blocksByArea: [],
    };
  },

  methods: {
    close() {
      this.show_dialog_box = false;
    },
    getBrandList() {
      this.loading = true;
      this.$apollo
        .query({
          query: require("@/stock/api/getAllBrands.graphql"),
        })

        .then((data) => {
          if (data.data) {
            this.brandList = data.data.listAllBrands;
          }
          console.log(this.brandList);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    getAllAreas() {
      this.loading = true;
      this.$apollo
        .query({
          query: require("@/customer/api/getAllAreas.graphql"),
          variables: {
            searchKey: "",
          },
        })
        .then((data) => {
          this.areasList = data.data.getAllAreas.areas;
        });
    },
    getBlocksByArea(area) {
      if (area) {
        this.$apollo
          .query({
            query: require("@/customer/api/getBlocksByArea.graphql"),
            variables: {
              id: area,
            },
          })
          .then((data) => {
            this.blocksByArea = data.data.getBlocksByArea.blocks;
          });
      }
    },

    createVendor() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/createVendor.graphql"),
          variables: {
            nameEn: this.nameEn,
            companyNameEn: this.companyNameEn,
            companyNameAr: this.companyNameAr,
            phone: this.phone,
            contactPersonName: this.contactPersonName,
            contactPersonPhone: this.contactPersonPhone,
            contactPersonType: this.contactPersonType,
            brands: this.brands,
            area: this.area,
            block: this.block,
            jedha: this.jedha,
            street: this.street,
          },
        })
        .then(() => {
          this.$root.$emit("updateVendor");
          this.$refs.form.reset();
          this.isActive = true;
          this.btnloading = false;
          this.show_dialog_box = false;
        })
        .catch((error) => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
  },
};
</script>

<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
