<template>
  <v-container class="mt-n6 pa-0">
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class="black--text text-capitalize font-weight-bold"
          color="secondary"
        >
          <v-icon class="me-1" small>mdi-plus</v-icon> Add
        </v-btn>
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 black--text">Add Staff</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form
          class="pa-sm-8 mt-n6"
          @submit="createManager()"
          v-model="valid"
          ref="form"
        >
          <v-card-text>
            <v-container>
              <v-layout wrap class="mt-4 mt-sm-0">
                <v-flex xs12 md3>
                  <h4 class="text-body-2 black--text">First Name</h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-text-field
                    label="First Name"
                    v-model="firstnameEn"
                    :rules="[requiredValidator('First Name')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 md3>
                  <h4 class="text-body-2 black--text">Last Name</h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-text-field
                    label="Last Name"
                    v-model="lastnameEn"
                    :rules="[requiredValidator('Last Name ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 md3>
                  <h4 class="text-body-2 black--text">Phone</h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-text-field
                    v-model="phone"
                    :counter="8"
                    :error-messages="errors"
                    label="Phone"
                    required
                    solo
                    dense
                    @keypress="isNumber"
                    type="number"
                    class="inputPhone"
                    @click="doesPhoneExist = false"
                    :rules="[
                      requiredValidator('phone'),
                      minLengthValidator('phone', 8),
                      maxLengthValidator('phone', 8),
                      this.doesPhoneExist != true || 'phone already exists.',
                    ]"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 md3>
                  <h4 class="text-body-2 black--text">Password</h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-text-field
                    label="Password"
                    v-model="password"
                    :append-icon="
                      show1 ? 'mdi-eye black--text' : 'mdi-eye-off black--text'
                    "
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    @click:append="show1 = !show1"
                    :rules="[
                      requiredValidator('password'),
                      minLengthValidator('password', 8),
                    ]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <!-- <v-flex
                  md3
                  v-for="per in permissionsList"
                  :key="per.key"
                  :class="per.value == 'Operation' ? 'ms-md-n7 me-md-2' : ''"
                >
                  <v-checkbox
                    :label="per.value"
                    v-model="permission"
                    :value="per.value"
                    class="mt-n1"
                  ></v-checkbox>
                </v-flex> -->
              </v-layout>
              <v-layout wrap class="mb-3">
                <v-flex xs10 md6 offset-md-3>
                  <v-checkbox label="Is Active" v-model="isActive"></v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-n2 mt-sm-0">
                <v-flex xs12 md3>
                  <h4 class="black--text text-body-2">Referral Percent</h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-text-field
                    placeholder="Referral Percent"
                    v-model="percentage_one"
                    type="number"
                    min="0"
                    append-icon="mdi-percent"
                    :rules="[requiredValidator('referral percentage ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-n2 mt-sm-0">
                <v-flex xs12 md3>
                  <h4 class="black--text text-body-2">Referral Percent</h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-text-field
                    placeholder="Referral Percent"
                    v-model="percentage_two"
                    type="number"
                    min="0"
                    append-icon="mdi-percent"
                    :rules="[requiredValidator('referral percentage ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-n2 mt-sm-0">
                <v-flex xs12 md3>
                  <h4 class="black--text text-body-2">Referral Percent</h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-text-field
                    placeholder="Referral Percent"
                    v-model="percentage_three"
                    type="number"
                    min="0"
                    append-icon="mdi-percent"
                    :rules="[requiredValidator('referral percentage ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-n2 mt-sm-0">
                <v-flex xs12 md3>
                  <h4 class="black--text text-body-2">
                    Max. Referral Amount(KD)
                  </h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-text-field
                    placeholder="Max. Referral Amount"
                    v-model="maxReferralAmount"
                    type="number"
                    min="0"
                    :rules="[requiredValidator('max. referral percentage ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 md3>
                  <h4 class="text-body-2 black--text">Role</h4>
                </v-flex>
                <v-flex sm8 md7>
                  <v-select
                    :items="userTypes"
                    v-model="userRoleId"
                    item-text="role"
                    item-value="_id"
                    label="User Type"
                    dense
                    attach
                    :rules="[requiredValidatorSelect('role')]"
                    solo
                    @change="userRoleDisabled = false"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions class="mt-n4 mt-sm-0 mb-sm-n5">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              class="black--text text-capitalize"
              :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
              :loading="btnloading"
              @click="createManager"
              :disabled="!valid || userRoleDisabled"
              >Add</v-btn
            >
          </v-card-actions>
        </v-form>
        <v-snackbar
          v-model="snackbar"
          timeout="3000"
          right
          top
          :color="snackbarColor"
          >{{ text }}</v-snackbar
        >
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  requiredValidator,
  minLengthValidator,
  maxLengthValidator,
  requiredValidatorSelect,
} from "../../core/methods/validations";

export default {
  apollo: {
    getAllUserRole() {
      return {
        query: require("../../permission/api/getAllUserRole.graphql"),
        result({ data }) {
          this.userTypes = data.getAllUserRole;
        },
      };
    },
  },
  data() {
    return {
      minLengthValidator: minLengthValidator,
      maxLengthValidator: maxLengthValidator,
      requiredValidator: requiredValidator,
      requiredValidatorSelect,
      dialog: false,
      doesPhoneExist: false,
      addedArea: [],
      firstnameEn: "",
      lastnameEn: "",
      // referredBy: "",
      btnloading: false,
      phone: "",
      percentage_one: "",
      percentage_two: "",
      percentage_three: "",
      maxReferralAmount: "",
      password: "",
      show1: false,
      errors: [],
      permissionsList: [
        { key: "1", value: "Sales" },
        { key: " 2", value: "Operation" },
        { key: " 3", value: "Reports" },
      ],
      permission: [],
      snackbar: false,
      snackbarColor: "",
      text: "",
      valid: true,
      isActive: false,
      userTypes: [],
      userRoleId: "",
      userRoleDisabled: true,
    };
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    createManager() {
      this.btnloading = true;

      this.$apollo
        .mutate({
          mutation: require("../api/createManager.graphql"),
          variables: {
            firstnameEn: this.firstnameEn,
            lastnameEn: this.lastnameEn,
            phone: this.phone,
            password: this.password,
            isActive: this.isActive,
            permission: this.permission,
            percentage_one: parseFloat(this.percentage_one),
            percentage_two: parseFloat(this.percentage_two),
            percentage_three: parseFloat(this.percentage_three),
            maxReferralAmount: parseFloat(this.maxReferralAmount),
            userType: this.userRoleId,
            // referredBy: referredUserCode,
          },
          update: () => {
            try {
              this.$root.$emit("updateManager");
              this.text = "Manager Added";
              this.snackbarColor = "success";
              this.snackbar = true;
            } catch (e) {
              console.log(e);
              console.log("Something bad happend");
            }
            this.$refs.form.reset();
            this.btnloading = false;
          },
        })
        .then(() => {
          this.dialog = false;
        })
        .catch((error) => {
          if (error.graphQLErrors != null) {
            if (error.message === "GraphQL error: PHONE_ALREADY_EXIST") {
              this.doesPhoneExist = true;
            } else {
              this.text = error.graphQLErrors[0].message;
              this.snackbarColor = "error";
              this.snackbar = true;
            }
          }

          this.btnloading = false;
          console.log(error);
        });
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>
<style>
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  color: black !important;
}
</style>
