<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="550px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class="black--text text-capitalize font-weight-bold"
          color="secondary"
          ><v-icon small class="me-1">mdi-plus</v-icon> Add</v-btn
        >
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-row>
          <v-card-title class="black--text text-h6 mt-n2 ms-2"
            >Add Diet Plan</v-card-title
          >
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-row>
        <v-divider></v-divider>

        <v-card-text>
          <v-container class="mt-2">
            <!-- <h3 class="ms-sm-8">Enter Contact Method Details</h3> -->
            <v-form @submit="createDietPlan()" v-model="valid" ref="form">
              <v-layout wrap>
                <v-flex xs12 sm5>
                  <h4 class="text-body-2 black--text">Diet Plan English</h4>
                </v-flex>
                <v-flex xs12 sm7 md6>
                  <v-text-field
                    v-model="nameEn"
                    :rules="[requiredValidator('diet plan')]"
                    solo
                    placeholder="Diet Plan English"
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm5>
                  <h4 class="text-body-2 black--text">Diet Plan Arabic</h4>
                </v-flex>
                <v-flex xs12 sm7 md6>
                  <v-text-field
                    v-model="nameAr"
                    placeholder="Diet Plan Arabic"
                    :rules="[requiredValidator('diet plan')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm5>
                  <h4 class="text-body-2 black--text">Currently Active</h4>
                </v-flex>
                <v-flex xs12 sm7 md6>
                  <v-radio-group row v-model="isActive">
                    <v-radio label="Yes" :value="true"> </v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm5>
                  <h4 class="text-body-2 black--text">Show Order</h4>
                </v-flex>
                <v-flex xs12 sm7 md6>
                  <v-text-field
                    solo
                    dense
                    placeholder="Show Order"
                    v-model="showOrder"
                    @keypress="isNumber"
                    min="0"
                    :rules="[requiredValidator('order')]"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout row justify-end class="mt-5">
                <v-btn
                  :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
                  color="secondary"
                  class="black--text text-capitalize"
                  @click="createDietPlan()"
                  :loading="btnloading"
                  :disabled="!valid"
                  >Save</v-btn
                >
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
      text
    }}</v-snackbar>
  </div>
</template>

<script>
import { requiredValidator } from "../../../core/methods/validations";
export default {
  components: {},
  data() {
    return {
      requiredValidator: requiredValidator,
      method: "",
      methodAr: "",
      btnloading: false,
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      isActive: true,
      nameEn: "",
      nameAr: "",
      showOrder: "",
      show_dialog_box: false
    };
  },
  methods: {
    close() {
      this.show_dialog_box = false;
    },
    createDietPlan() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/createDietPlan.graphql"),
          variables: {
            nameEn: this.nameEn,
            nameAr: this.nameAr,
            active: this.isActive,
            showOrder: parseInt(this.showOrder)
          }
        })
        .then(() => {
          this.$root.$emit("updateDietPlan");
          this.$refs.form.reset();
          this.isActive = true;
          this.btnloading = false;
          this.show_dialog_box = false;
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    }
  }
};
</script>
