<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class="black--text text-capitalize font-weight-bold"
          color="secondary"
        >
          <v-icon small class="me-1">mdi-plus</v-icon> Add
        </v-btn>
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 black--text">Add Zone</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form
          class="pa-sm-8 mt-n6"
          @submit="createZone()"
          v-model="valid"
          ref="form"
        >
          <v-card-text>
            <v-container>
              <v-layout class="mt-3 mt-sm-0" wrap>
                <v-flex xs12 sm4 md3>
                  <h4 class="black--text text-body-2">Enter zone name</h4>
                </v-flex>
                <v-flex xs12 sm7 md5>
                  <v-text-field
                    label="Enter zone name"
                    v-model="zoneName"
                    :rules="[requiredValidator('zone Name')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-col
                  cols="8"
                  sm="10"
                  md="10"
                  lg="11"
                  class="font-weight-medium text-h6 ms-n3"
                >
                  Area
                </v-col>
                <v-col cols="6" sm="2" md="2" lg="1"
                  ><v-btn
                    small
                    color="primary"
                    class="white--text text-capitalize mt-3"
                    @click="add"
                    >+ Add</v-btn
                  ></v-col
                >
              </v-layout>
              <v-layout
                class="mt-3 mt-sm-5 mt-md-0"
                v-for="(areaDetail, i) in areaDetails"
                :key="i"
                wrap
              >
                <v-flex xs12 sm5 md4 class="mt-md-0 pe-6">
                  <v-autocomplete
                    item-text="nameEn"
                    v-model="areaDetail.area"
                    @change="getBlocksByArea(areaDetail)"
                    item-value="_id"
                    :items="areaList"
                    :rules="[requiredValidator('area ')]"
                    label="Select Area"
                    dense
                    attach
                    solo
                  >
                    <template slot="no-data">
                      <v-flex xs12 md12 class="mt-2 mb-2">
                        <h5 class="font-weight-medium ml-4">
                          Search for area...
                        </h5>
                      </v-flex>
                    </template>
                  </v-autocomplete>
                </v-flex>

                <v-flex xs9 sm4 md4 class="ms-sm-16">
                  <v-select
                    v-model="areaDetail.block"
                    :items="areaDetail.blockList"
                    solo
                    dense
                    item-value="_id"
                    item-text="block"
                    attach
                    chips
                    small-chips
                    label="Select Block"
                    multiple
                    deletable-chips
                  ></v-select>
                </v-flex>

                <template>
                  <v-icon
                    x-large
                    color="primary"
                    class="mt-n7 ms-2"
                    @click="remove(i)"
                    :disabled="areaDetails.length == 1"
                    >mdi-close-box</v-icon
                  >
                </template>
              </v-layout>
              <v-layout justify-center>
                <v-flex xs8 md2 class="mt-8"> </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions class="mb-sm-n4 mt-n16">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              class="black--text text-capitalize"
              :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
              :loading="btnloading"
              @click="createZone"
              :disabled="!valid"
              >Add</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      right
      top
      :color="snackbarColor"
      >{{ text }}</v-snackbar
    >
  </div>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
export default {
  data() {
    return {
      requiredValidator: requiredValidator,
      dialog: false,
      btnloading: false,
      zoneName: "",
      areaList: [],
      addedArea: [],
      block: "",
      area: "",
      snackbar: false,
      snackbarColor: "",
      text: "",
      valid: true,
      variables: {
        limit: 25,
        skip: 0
      },
      areaDetails: [],
      blockList: []
    };
  },
  apollo: {
    getAllAreas() {
      return {
        query: require("../api/getAllAreas.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.areaList = data.getAllAreas.areas;
          this.totalItemsCount = data.getAllAreas.totalCount;
        }
      };
    }
  },
  created() {
    this.add();
  },
  methods: {
    getBlocksByArea(item) {
      this.$apollo
        .query({
          query: require("../api/getBlocksByArea.graphql"),
          variables: {
            id: item.area
          }
        })
        .then(data => {
          item.blockList = data.data.getBlocksByArea.blocks;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    createZone() {
      this.btnloading = true;
      let blockValue;
      let areaData = new Array(0);
      this.areaDetails.forEach(element => {
        console.log(element.block.length);
        if (element.block.length === 0) {
          blockValue = null;
        } else {
          blockValue = element.block;
        }
        areaData.push({ area: element.area, block: blockValue });
      });

      console.log(areaData);
      this.$apollo
        .mutate({
          mutation: require("../api/createZone.graphql"),
          variables: {
            zoneName: this.zoneName,
            areaDetails: areaData
          },
          update: () => {
            try {
              this.$root.$emit("updateZone");
              this.text = "Zone Added";
              this.snackbarColor = "success";
              this.snackbar = true;
            } catch (e) {
              console.log(e);
              console.log("Something bad happend");
            }
            this.$refs.form.reset();
            this.btnloading = false;
            this.dialog = false;
          }
        })
        .catch(error => {
          this.btnloading = false;
          console.log(error.graphQLErrors[0].message);
          this.text = error.graphQLErrors[0].message;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },

    close() {
      this.dialog = false;
    },
    add() {
      //  this.sectionId = this.sectionId + 1;
      const section = {
        area: "",
        block: "",
        blockList: []
      };
      this.areaDetails.push(section);
    },
    remove(index) {
      this.areaDetails.splice(index, 1);
    }
  }
};
</script>
