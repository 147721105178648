<template>
  <v-container :key="selectedDay.index">
    <v-icon color="black" @click="showDialogBox"
      >mdi-square-edit-outline</v-icon
    >
    <v-dialog v-model="showDialog" max-width="600">
      <v-card class="pa-8 pa-md-12" color="pop_bg">
        <v-row class="mt-n4 mt-md-n8">
          <v-card-title class="black--text text-h6 mt-n2"
            >Meal Selection</v-card-title
          >
          <v-spacer></v-spacer>

          <v-btn color="black" text @click="showDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-row>

        <v-divider class="mb-4"></v-divider>
        <v-progress-linear
          indeterminate
          v-if="progressLoader"
          color="primary"
        ></v-progress-linear>
        <v-form ref="form" v-model="valid">
          <div v-for="(item, index) in menu" :key="index">
            <v-card flat v-if="item.mealsList.length > 0">
              <v-flex xs12 md12 class="pop_bg">
                <h4 class="font-weight-medium black--text text-body-2">
                  {{ item.nameEn }} -
                  {{ item.mealsList.filter(x => x.item != "").length }}
                  of
                  {{ item.mealsList.length }}
                </h4>
              </v-flex>

              <div v-for="meals in item.mealsList" :key="meals._id">
                <v-layout wrap class="pop_bg">
                  <v-flex cols12>
                    <v-autocomplete
                      class="mt-2"
                      :items="item.menuItem"
                      :label="item.nameEn"
                      solo
                      dense
                      attach
                      @change="checkForDislike(item.menuItem, meals)"
                      v-model="meals.item"
                      item-text="nameEn"
                      item-value="_id"
                      :rules="[requiredValidator('item name')]"
                    >
                      <template slot="no-data">
                        <v-flex xs12 md12 class="mt-2 mb-2">
                          <h5 class="font-weight-medium ml-4">
                            No items found...
                          </h5>
                        </v-flex>
                      </template></v-autocomplete
                    >
                  </v-flex>

                  <v-flex class="ml-2" md4 v-if="meals.isDisliked">
                    <h5 class="font-weight-medium ml-4" style="color: red">
                      Contains Disliked Ingredients
                    </h5></v-flex
                  >
                </v-layout>
              </div>
            </v-card>
            <v-spacer class="mt-5"></v-spacer>
          </div>
        </v-form>
        <v-snackbar
          v-model="snackbar"
          timeout="3000"
          right
          top
          :color="snackColor"
          >{{ text }}</v-snackbar
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            class="black--text text-capitalize"
            :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
            @click="saveMealSelection"
            :disabled="!valid"
            :loading="loading"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { requiredValidator } from "../../../core/methods/validations";
import VueLodash from "vue-lodash";
import Vue from "vue";
import lodash from "lodash";
import moment from "moment";
Vue.use(VueLodash, { name: "custom", lodash: lodash });
export default Vue.extend({
  props: {
    selectedDay: {
      require: true
    },
    activePackage: {
      require: true
    }
  },
  components: {},

  // watch: {
  //   selectedDay: {
  //     handler() {
  //       debugger;
  //       this.getAllCategoryWiseMenu();
  //       this.getCustomerDetails();
  //     },
  //     deep: true,
  //   },
  // },

  methods: {
    checkForDislike(meals, item) {
      item.isDisliked = false;
      let selectedMeal = meals.find(x => x._id == item.item);
      if (selectedMeal) {
        let ingredientIds = selectedMeal.ingredients.map(x => x.ingredient._id);
        let dislikedIds = this.dislikedIngredients.map(x => x._id);
        let intersec = this.lodash.intersection(ingredientIds, dislikedIds);
        intersec.forEach(element => {
          let dislikedIng = selectedMeal.ingredients.find(
            x => x.ingredient._id == element
          );
          if (!dislikedIng.isDislikable) {
            item.isDisliked = true;
          }
        });
      }
    },
    getCustomerDetails() {
      this.$apollo
        .watchQuery({
          query: require("../../api/getCustomerById.graphql"),
          variables: {
            id: this.$route.query.customerId
          }
        })
        .refetch({
          variables: {
            id: this.$route.query.customerId
          }
        })
        .then(data => {
          if (data.data.getCustomerById) {
            this.dislikedIngredients = data.data.getCustomerById.dislikes;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    getAvailabelWeekDaysInNumbers(availabelWeekDays) {
      let availabelWeekDaysInNumbers = new Array(0);
      if (availabelWeekDays == null) return [];
      availabelWeekDays.forEach(element => {
        switch (element) {
          case "Sunday":
            availabelWeekDaysInNumbers.push(0);
            break;
          case "Monday":
            availabelWeekDaysInNumbers.push(1);
            break;
          case "Tuesday":
            availabelWeekDaysInNumbers.push(2);
            break;
          case "Wednesday":
            availabelWeekDaysInNumbers.push(3);
            break;
          case "Thursday":
            availabelWeekDaysInNumbers.push(4);
            break;
          case "Friday":
            availabelWeekDaysInNumbers.push(5);
            break;
          case "Saturday":
            availabelWeekDaysInNumbers.push(6);
            break;
          default:
        }
      });
      return availabelWeekDaysInNumbers;
    },

    isPackageNotAvailableOnThisDay(date, availabelWeekDaysInNumbers) {
      let day = date.day();
      let available = availabelWeekDaysInNumbers.filter(x => x == day);
      return available.length > 0 ? false : true;
    },

    getAllCategoryWiseMenu() {
      this.progressLoader = true;
      let selectedDate = moment(this.selectedDay.date);
      let dayOftheDate = this.dayOfWeekAsString(selectedDate.day());

      this.$apollo
        .watchQuery({
          query: require("../../api/getAllCategoryWiseMenu.graphql"),
          variables: {
            customerId: this.$route.query.customerId,
            subscribedPackageId: this.activePackage._id,
            weekOfTheDay: dayOftheDate,
            date: selectedDate.format("MM-DD-YYYY")
          }
        })
        .refetch({
          variables: {
            customerId: this.$route.query.customerId,
            subscribedPackageId: this.activePackage._id,
            weekOfTheDay: dayOftheDate
          }
        })
        .then(data => {
          //load all category and menu under category
          this.progressLoader = false;
          if (data.data.getAllAdminCategoryWiseMenu) {
            let menu = JSON.parse(
              JSON.stringify(data.data.getAllAdminCategoryWiseMenu)
            );

            menu.forEach(element => {
              let menuItems = new Array();
              element.menuItem.forEach(element => {
                let availableDays = this.getAvailabelWeekDaysInNumbers(
                  element.availableWeekdays
                );
                let date = moment(this.selectedDay.date);
                let isNotAvailable = this.isPackageNotAvailableOnThisDay(
                  date,
                  availableDays
                );

                if (!isNotAvailable && element.active) {
                  menuItems.push(element);
                }
              });
              element.menuItem = menuItems;

              let allMeals = [];
              let mealCategory = [];
              if (this.selectedDay.allMeals)
                allMeals = this.selectedDay.allMeals;

              if (allMeals.length > 0) {
                mealCategory = allMeals.filter(
                  x => x.category._id == element._id
                );
              }

              let count = this.getCount(element); //find total meals count under the category
              element.mealsList = new Array(0);
              for (let index = 1; index <= count; index++) {
                let item = "";
                if (mealCategory.length >= index) {
                  //assign already selected menu to list
                  if (mealCategory[index - 1].menu_item.length > 0) {
                    item = mealCategory[index - 1].menu_item[0]._id;
                  }
                }
                let meals = {
                  item: item
                };
                element.mealsList.push(meals);
              }
            });

            this.menu = menu;
          }
        })
        .catch(error => {
          this.progressLoader = false;
          console.log(error);
        });
    },
    getCount(item) {
      let date = moment(this.selectedDay.date);
      let dayOftheDate = this.dayOfWeekAsString(date.day());

      let dayChoice = this.activePackage.package.dayChoices.find(
        x => x.day == dayOftheDate
      );

      let mealCount = 0;
      let mealItem = dayChoice.mealCount.find(
        x => x.menuCategoryId == item._id
      );
      if (mealItem) {
        mealCount = parseInt(mealItem.count);
      }
      return mealCount;
    },
    showDialogBox() {
      this.getAllCategoryWiseMenu();
      this.getCustomerDetails();
      this.showDialog = true;
    },
    dayOfWeekAsString(dayIndex) {
      return (
        [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday"
        ][dayIndex] || ""
      );
    },
    saveMealSelection() {
      this.loading = true;
      let menuItems = new Array(0);
      this.menu.forEach(menuItem => {
        menuItem.mealsList.forEach(meal => {
          let item = {
            category: menuItem._id,
            menu_item: meal.item
          };
          menuItems.push(item);
        });
      });
      let input = {
        date: moment(this.selectedDay.date).format("MM-DD-YYYY"),
        subscribed_package_id: this.activePackage._id,
        menu_category: menuItems
      };
      this.$apollo
        .mutate({
          mutation: require("../../api/adminChooseCustomerMenu.graphql"),
          variables: {
            input: input,
            customerId: this.$route.query.customerId,
            subscribed_package_id: this.activePackage._id
          },
          update: () => {
            this.showDialog = false;
          }
        })
        .then(() => {
          this.$root.$emit("updateGetCustomerById");
          this.loading = false;
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
            this.text = error.graphQLErrors[0].message;
            this.snackColor = "red";
            this.snackbar = true;
          }
          this.loading = false;
        });
    }
  },

  created() {},
  data() {
    return {
      requiredValidator: requiredValidator,
      showDialog: false,
      dislikedIngredients: [],
      menu: [],
      valid: false,
      loading: false,
      progressLoader: false,
      snackbar: false,
      snackColor: "",
      text: ""
    };
  }
});
</script>
