<template>
  <v-container class="mt-n6 pa-0">
    <v-btn
      class="black--text text-capitalize"
      color="secondary"
      @click="showIngrediantDialog()"
      ><v-icon small class="me-1">mdi-plus</v-icon> Add</v-btn
    >

    <v-dialog v-model="show_dialog_box" max-width="1000px">
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 black--text">Add Ingredient</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="show_dialog_box = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-container class="mt-2">
            <v-form @submit="createIngredient()" v-model="valid" ref="form">
              <v-layout wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="black--text text-body-2">Item ID</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-text-field
                    solo
                    dense
                    label="Item ID"
                    :disabled="true"
                    :rules="[requiredValidator('item id')]"
                    v-model="itemId"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md2 class="ms-md-16">
                  <h4 class="black--text text-body-2">Brand Name</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-autocomplete
                    item-text="nameEn"
                    v-model="brand"
                    item-value="_id"
                    :items="brandList"
                    placeholder="Select Brand"
                    attach
                    dense
                    solo
                  >
                  </v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="black--text text-body-2">Item Name</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-text-field
                    v-model="itemName"
                    :rules="[requiredValidator('item name')]"
                    solo
                 
                    dense
                    label="Item Name"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md2 class="ms-md-16">
                  <h4 class="black--text text-body-2">Item Name Arabic</h4>
                </v-flex>
                <v-flex md3>
                  <v-text-field
                    v-model="itemNameAr"
                    :rules="[requiredValidator('item name arabic')]"
                    solo
                   
                    dense
                    label="Item Name Arabic"
                  ></v-text-field>
                </v-flex>
                <!-- <v-flex xs12 sm4 md2>
                  <h4>Label</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-text-field
                    label="Label"
                    v-model="itemLabel"
                    outlined
                    dense
                    label=""
                  ></v-text-field>
                </v-flex> -->
              </v-layout>

              <v-layout wrap>
                <v-flex xs4 md2>
                  <h4 class="black--text text-body-2">Unit In</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-select
                    :items="unitList"
                    :rules="[requiredValidator('quantityUnit')]"
                    v-model="quantityUnit"
                    item-text="name"
                    item-value="_id"
                    dense
                    attach
                    label="select quantity unit"
                    solo
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm4 md2 class="ms-md-16" v-if="quantityUnit">
                  <h4 class="black--text text-body-2">
                    Price(/1{{ priceUnit(quantityUnit) }})
                  </h4>
                </v-flex>
                <v-flex md3 v-if="quantityUnit">
                  <v-text-field
                    v-model="quantityPrice"
                    type="number"
                    min="0"
                    @keypress="formatDecimal"
                    :rules="[requiredValidator('price')]"
                    solo
                    dense
                    label="Price"
                  >
                    <template v-slot:append>
                      <h5 class="mt-1 grey--text">KD</h5>
                    </template>
                  </v-text-field>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex xs4 md2>
                  <h4 class="black--text text-body-2">Supplier</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-autocomplete
                    item-text="companyNameEn"
                    v-model="vendor"
                    item-value="_id"
                    :items="suppliers"
                    placeholder="Select Supplier"
                    attach
                    dense
                    solo
                  >
                  </v-autocomplete>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs4 md2 class="mt-5">
                  <h4 class="black--text text-body-2">Is Ingredient</h4>
                </v-flex>
                <v-flex md3 align-self-center>
                  <v-radio-group v-model="isIngredient" :mandatory="false" row>
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
              <v-layout wrap v-show="isIngredient">
                <v-flex xs4 md2 class="pe-md-4">
                  <h4 class="black--text text-body-2">Common Dislikable</h4>
                </v-flex>
                <v-flex md3 align-self-center class="mt-n5">
                  <v-radio-group v-model="isCommonDislikable" :mandatory="false" row>
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
              <v-layout class="mt-4" v-show="true">
                <h3 class="black--text font-weight-bold">Add Per Gram</h3>
              </v-layout>
              <v-layout wrap class="mt-4" v-show="true">
                <v-flex xs5 sm4 md3>
                  <h4 class="black--text text-body-2 mt-3">Fat</h4>
                </v-flex>
                <v-flex xs6 sm5 md2 class="ms-md-n16">
                  <v-text-field
                    v-model="fat"
                    min="0"
                    @keypress="formatDecimal"
                    solo
                    type="number"
                    suffix="gm"
                  ></v-text-field>
                </v-flex>
                <v-flex xs5 sm4 md3 class="ms-md-16">
                  <h4 class="black--text text-body-2 mt-3">Protein</h4>
                </v-flex>
                <v-flex xs6 sm5 md2 class="ms-md-n16">
                  <v-text-field
                    v-model="protein"
                    solo
                    min="0"
                    @keypress="formatDecimal"
                    type="number"
                    suffix="gm"
                  ></v-text-field>
                </v-flex>
                <v-flex xs5 sm4 md3>
                  <h4 class="black--text text-body-2 mt-3">Carb</h4>
                </v-flex>
                <v-flex xs6 sm5 md2 class="ms-md-n16">
                  <v-text-field
                    v-model="carb"
                    solo
                    min="0"
                    @keypress="formatDecimal"
                    type="number"
                    suffix="gm"
                  ></v-text-field>
                </v-flex>
                <v-flex xs5 sm4 md3 class="ms-md-16">
                  <h4 class="black--text text-body-2 mt-3">Calories</h4>
                </v-flex>
                <v-flex xs6 sm5 md2 class="ms-md-n16">
                  <v-text-field
                    v-model="calories"
                    solo
                    min="0"
                    @keypress="formatDecimal"
                    type="number"
                    suffix="gm"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <h3 class="black--text">Alert</h3>
              <v-layout class="mt-2" wrap>
                <v-flex xs12 sm4 md6>
                  <h4 class="red--text font-weight-light">
                    Notify Admin when the quantity goes below*
                  </h4>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-2">
                <v-flex xs12 sm4 md2 class="pe-md-4">
                  <h4 class="black--text text-body-2">Minimum Quantity Level</h4>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    label="Qty"
                    solo
                    dense
                    min="0"
                    :rules="[requiredValidator('minimum quatity level')]"
                    @keypress="formatDecimal"
                    type="number"
                    v-model="alertQuantity"
                  >
                    <template v-if="quantityUnit" v-slot:append>
                      <h4 class="mt-1 grey--text">
                        {{ priceUnit(quantityUnit) }}
                      </h4>
                    </template></v-text-field
                  >
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="mt-n16">
          <v-spacer></v-spacer>
          <v-btn
            :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
            color="secondary "
            @click="createIngredient()"
            :loading="btnloading"
            :disabled="!valid"
            class="mt-8 black--text text-capitalize"
            >Add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">
      {{ text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";

export default {
  computed: {
    quantityUnitRule() {
      return [
        (this.quantityUnit && this.quantityUnit.length) > 0 ||
          "At least one item should be selected",
      ];
    },
  },
  props: {
    unitList: {
      type: Array,
      required: true,
    },
  },
  apollo: {
    listAllVendors() {
      return {
        query: require("../api/listAllVendors.graphql"),
        variables: {
          skip: 0,
          limit: 0,
        },
        result({ data, loading }) {
          this.suppliers = data.listAllVendors;
          this.loading = loading;
        },
      };
    },
    listAllBrands() {
      return {
        query: require("@/stock/brand/api/listAllBrands.graphql"),

        result({ data, loading }) {
          this.loading = loading;
          this.brandList = data.listAllBrands;
        },
      };
    },
  },
  data() {
    return {
      suppliers: [],
      brandList: [],
      btnloading: false,
      isIngredient: true,
      isCommonDislikable: true,
      loading: false,
      itemId: "",
      quantityPrice: "",
      itemName: "",
      brandName: "",
      brand: null,
      vendor: null,
      itemLabel: "",
      quantityUnit: "",
      itemNameAr: "",
      alertQuantity: "",
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      show_dialog_box: false,
      protein: "0",
      fat: "0",
      carb: "0",
      calories: "0",
      requiredValidator: requiredValidator,
    };
  },

  methods: {
    priceUnit(id) {
      let unit = "";
      if (id) {
        let data = this.unitList.find((x) => x._id == id);
        if (data) {
          if (data.conversionUnit == "ML") unit = "L";
          else if (data.conversionUnit == "GRAMS") unit = "KG";
          else if (data.conversionUnit == "PIECE") unit = "PCS";
        }
      }
      return unit;
    },

    getNewIngredientCode() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getNewIngredientCode.graphql"),
        })
        .refetch({})
        .then((data) => {
          this.loading = false;
          if (data.data.getNewIngredientCode) {
            this.itemId = data.data.getNewIngredientCode;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    showIngrediantDialog() {
      this.show_dialog_box = true;
      this.getNewIngredientCode();
    },
    formatDecimal($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || this.updatedQuantity.indexOf(".") != -1)
      ) {
        // 46 is dot
        $event.preventDefault();
      }
      if (
        this.alertQuantity != null &&
        this.alertQuantity.indexOf(".") > -1 &&
        this.alertQuantity.split(".")[1].length > 2
      ) {
        $event.preventDefault();
      }
    },
    clearFields() {
      this.itemId = "";
      this.itemName = "";
      this.itemNameAr = "";
      this.brandName = "";
      this.itemLabel = "";
      this.quantityUnit = "";
      this.alertQuantity = "";
      this.$refs.form.reset();
    },
    createIngredient() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("@/stock/api/createIngredient.graphql"),
          variables: {
            itemId: this.itemId,
            nameEn: this.itemName,
            nameAr: this.itemNameAr,
            quantityUnit: this.quantityUnit,
            isIngredient: this.isIngredient,
            quantityPrice:
              this.quantityUnit == "PIECE"
                ? parseFloat(this.quantityPrice)
                : parseFloat(this.quantityPrice / 1000),
            isCommonDislikable:
              this.isIngredient == false ? false : this.isCommonDislikable,
            brand: this.brand,
            vendor: this.vendor,
            label: this.itemLabel,
            alertQuantity: parseFloat(this.alertQuantity).toFixed(3),
            meanInfo: {
              protein: this.protein.toString(),
              fat: this.fat.toString(),
              carbs: this.carb.toString(),
              calories: this.calories.toString(),
            },
          },
          update: (store, { data: { createIngredient } }) => {
            try {
              const query = {
                query: require("@/stock/api/getAllIngredients.graphql"),
                variables: {
                  limit: 25,
                  skip: 0,
                },
              };

              let allIngred = store.readQuery(query);

              allIngred.getAllIngredients.ingredients.unshift(createIngredient); //push to top
              store.writeQuery({
                ...query,
                data: allIngred,
              });

              this.text = "Ingredient Added";
              this.snackbar = true;
              this.show_dialog_box = false;
              this.btnloading = false;
              this.clearFields();
            } catch (e) {
              console.log("Something bad happend- StockMasterForm.vue");
              this.btnloading = false;
              this.show_dialog_box = false;
            }

            this.show_dialog_box = false;
            this.btnloading = false;
          },
        })

        .catch((error) => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
          this.show_dialog_box = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
