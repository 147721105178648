<template>
  <v-container fluid>
    <div class="dashboard-page">
      <v-row>
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="primary"
        ></v-progress-linear>
      </v-row>
      <v-row>
        <v-col lg="3" sm="6" md="5" cols="12">
          <v-card class="mx-1 mb-1" style="height: 240px">
            <v-card-title class="pa-6 pb-3">
              <p>Total Customers</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-6 pt-0">
              <v-row no-gutters class="pb-5">
                <v-col cols="5" class="my-auto">
                  <span class="font-weight-medium card-dark-grey" style="font-size: 24px">
                    {{ dashboardData.totalCustomers }}
                  </span>
                </v-col>
                <v-col cols="6">
                  <Trend
                    :data="dashboardData.customerTrend"
                    :gradient="mock.trend.gradient"
                    :height="40"
                    stroke-width="4"
                    smooth
                  />
                </v-col>
              </v-row>
              <v-row no-gutters class="justify-space-between pb-3">
                <v-col cols="12">
                  <div class="card-light-grey">Customers Today</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    {{ dashboardData.customersToday }}
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="3" sm="6" md="5" cols="12">
          <v-card class="mx-1 mb-1" style="height: 240px">
            <v-card-title class="pa-6 pb-3">
              <p>Total Sales Count</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-6 pt-0">
              <v-row no-gutters class="pb-5">
                <v-col cols="5" class="my-auto">
                  <span class="font-weight-medium card-dark-grey" style="font-size: 24px">
                    {{ dashboardData.totalSalesOrder }}
                  </span>
                </v-col>
                <v-col cols="6">
                  <Trend
                    :data="dashboardData.subscriptionTrend"
                    :gradient="mock.trend.gradient"
                    :height="40"
                    stroke-width="4"
                    smooth
                  />
                </v-col>
              </v-row>
              <v-row no-gutters class="justify-space-between pb-3">
                <v-col cols="12">
                  <div class="card-light-grey">Sales Count Today</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    {{ dashboardData.salesOrderToday }}
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col lg="3" sm="6" md="7" cols="12">
          <p class="mt-10 text-center" v-if="!hasDeliveryStatusCountData">
            No data available
          </p>
          <DeliveryStatusCard v-else @hasData="setDeliveryStatusNoData" />
        </v-col>

        <v-col lg="3" sm="6" md="5" cols="12">
          <TrendingMealCard />
        </v-col>

        <v-col cols="12" sm="6">
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-0">
              <v-row no-no-gutters>
                <v-col cols="12" lg="4">
                  <label class="text-h6 ms-6 mt-5">Customer</label>
                </v-col>

                <v-col cols="6" lg="4">
                  <v-select
                    class="ma-0 pa-0 mr-3"
                    background-color="#95D4E9"
                    :menu-props="{ top: false, offsetY: true }"
                    :items="['Year', 'Month']"
                    dense
                    solo
                    label="Type"
                    rounded
                    v-model="customerReportType"
                  ></v-select>
                </v-col>

                <v-col cols="6" lg="4" v-if="customerReportType == 'Year'">
                  <v-select
                    class="ma-0 pa-0 mr-3"
                    background-color="#95D4E9"
                    :menu-props="{ top: false, offsetY: true }"
                    :items="yearList"
                    item-text="text"
                    item-value="value"
                    dense
                    solo
                    rounded
                    label="Year"
                    v-model="customerYear"
                  ></v-select>
                </v-col>
                <v-col cols="6" lg="4" v-else>
                  <v-select
                    background-color="#95D4E9"
                    rounded
                    class="ma-0 pa-0 mr-3"
                    :menu-props="{ top: false, offsetY: true }"
                    :items="months"
                    item-text="text"
                    item-value="value"
                    dense
                    solo
                    label="Month"
                    v-model="customerMonth"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pa-6">
              <v-row>
                <v-col>
                  <CustomerGraph
                    class="mt-lg-8"
                    :customerReportType="customerReportType"
                    :customerYear="customerYear"
                    :customerMonth="customerMonth"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-0">
              <v-row no-no-gutters>
                <v-col cols="12" lg="4">
                  <label class="text-h6 ms-6 mt-5">Order Report </label>
                </v-col>

                <v-col cols="6" lg="4">
                  <v-select
                    class="ma-0 pa-0 mr-3"
                    background-color="#95D4E9"
                    :menu-props="{ top: false, offsetY: true }"
                    :items="['Year', 'Month']"
                    dense
                    solo
                    label="Type"
                    rounded
                    v-model="orderReportType"
                  ></v-select>
                </v-col>

                <v-col cols="6" lg="4" v-if="orderReportType == 'Year'">
                  <v-select
                    class="ma-0 pa-0 mr-3"
                    background-color="#95D4E9"
                    :menu-props="{ top: false, offsetY: true }"
                    :items="yearList"
                    item-text="text"
                    item-value="value"
                    dense
                    solo
                    rounded
                    label="Year"
                    v-model="orderYear"
                  ></v-select>
                </v-col>
                <v-col cols="6" lg="4" v-else>
                  <v-select
                    background-color="#95D4E9"
                    rounded
                    class="ma-0 pa-0 mr-3"
                    :menu-props="{ top: false, offsetY: true }"
                    :items="months"
                    item-text="text"
                    item-value="value"
                    dense
                    solo
                    label="Month"
                    v-model="orderMonth"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pa-6">
              <v-row>
                <v-col>
                  <OrderReport
                    class="mt-lg-8"
                    :orderReportType="orderReportType"
                    :orderYear="orderYear"
                    :orderMonth="orderMonth"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-0">
              <v-row no-no-gutters>
                <v-col cols="12" lg="4">
                  <label class="text-h6 ms-6 mt-5">Packages </label>
                </v-col>
                <v-col cols="6" lg="4"> </v-col>
                <v-col cols="6" lg="4">
                  <v-menu
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        solo
                        background-color="#95D4E9"
                        dense
                        rounded
                        :value="packageDate"
                        append-icon="fa-calendar-alt black--text"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="primary"
                      v-model="packageDateModel"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pa-10">
              <v-row>
                <v-col>
                  <PackageReport
                    :date="packageDate"
                    class="mt-n5 mt-sm-n10 mt-md-n12 mt-lg-n10 mb-n10 mb-md-0"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-0">
              <v-row no-no-gutters>
                <v-col cols="12" lg="4">
                  <label class="text-h6 ms-6 mt-5">Trending Meals </label>
                </v-col>
                <v-col cols="6" lg="4"> </v-col>
                <v-col cols="6" lg="4">
                  <v-select
                    background-color="#95D4E9"
                    rounded
                    solo
                    dense
                    class="ma-0 pa-0 mr-3"
                    :menu-props="{ top: false, offsetY: true }"
                    :items="months"
                    item-text="text"
                    item-value="value"
                    label="Month"
                    v-model="monthTrendingMeal"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pa-10">
              <v-row>
                <v-col>
                  <TrendingMealGraph
                    class="mt-n5 mt-sm-n10 mt-md-n12 mt-lg-n10 mb-n10 mb-md-0"
                    :month="monthTrendingMeal"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <SalesTrendGraphCard />
        </v-col>

        <v-col cols="12" sm="6">
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-0">
              <v-row no-no-gutters>
                <v-col cols="12" lg="12">
                  <label class="text-h6 ms-6 mt-5">Meal Rating </label>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pa-10">
              <v-row>
                <v-col>
                  <MealRatingCard class="mt-5 mt-md-7 mt-lg-5" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-0">
              <v-row no-no-gutters>
                <v-col cols="12" lg="12">
                  <label class="text-h6 ms-6 mt-5">Stock Alert </label>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pa-10">
              <v-row>
                <v-col>
                  <StockAlert class="mt-5 mt-md-7 mt-lg-5" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-0">
              <v-row no-no-gutters>
                <v-col cols="12" lg="12">
                  <label class="text-h6 ms-6 mt-5">Todays Customer Birthday </label>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pa-10">
              <v-row>
                <v-col>
                  <TodaysCustomerBirthday class="mt-5 mt-md-7 mt-lg-5" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="8">
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-0">
              <v-row no-no-gutters>
                <v-col cols="12" lg="12">
                  <label class="text-h6 ms-6 mt-5">Dietitians </label>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pa-10">
              <v-row>
                <v-col>
                  <DietitianCard class="mt-5 mt-md-7 mt-lg-5" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import mock from "./mock";
import Trend from "vuetrend";
// import ApexChart from "vue-apexcharts";
import { format, parseISO } from "date-fns";
import DeliveryStatusCard from "../../core/components/DeliveryStatusCard.vue";
import TrendingMealCard from "../../core/components/TrendingMealCard.vue";
// import SalesTrendGraph from "../../core/components/SalesTrendGraph.vue";
import CustomerGraph from "../../core/components/CustomerGraph.vue";
import moment from "moment";
import OrderReport from "../../core/components/OrderReport.vue";
import SalesTrendGraphCard from "../../core/components/SalesTrendGraphCard.vue";
import PackageReport from "../../core/components/PackageReport.vue";
import TrendingMealGraph from "../../core/components/TrendingMealGraph.vue";
import TodaysCustomerBirthday from "../components/TodaysCustomerBirthday.vue";
import DietitianCard from "../../core/components/DietitianCard.vue";
import MealRatingCard from "../../core/components/MealRatingCard";
import StockAlert from "../../core/components/StockAlert.vue";
export default {
  name: "Dashboard",
  components: {
    Trend,
    DeliveryStatusCard,
    TrendingMealCard,
    // SalesTrendGraph,
    CustomerGraph,
    OrderReport,
    SalesTrendGraphCard,
    PackageReport,
    TrendingMealGraph,
    TodaysCustomerBirthday,
    DietitianCard,
    MealRatingCard,
    StockAlert,
  },
  apollo: {
    getDashboardData() {
      return {
        query: require("../api/getDashboardData.graphql"),
        result({ data, loading }) {
          this.loading = loading;
          // console.log(data);
          this.dashboardData = data.getDashboardData;
          this.setDashboardData(this.dashboardData);
        },
      };
    },
  },
  computed: {
    packageDate() {
      return this.packageDateModel
        ? moment(this.packageDateModel).format("DD/MM/YYYY")
        : "";
    },
  },
  data() {
    return {
      mock,
      monthTrendingMeal: "",
      loading: true,
      packageDateModel: format(parseISO(moment().toDate().toISOString()), "yyyy-MM-dd"),
      customerReportType: "Year",
      dashboardData: [],
      apexLoading: false,
      value: this.getRandomInt(10, 90),
      value2: this.getRandomInt(10, 90),
      mainApexAreaSelect: "Daily",
      hasDeliveryStatusCountData: true,
      yearList: this.findYearList(),
      customerYear: moment().year(),
      customerMonth: moment().month() + 1,
      orderReportType: "Year",
      orderYear: moment().year(),
      orderMonth: moment().month() + 1,
      months: [
        { text: "January", value: 1 },
        { text: "February", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
      ],
    };
  },
  methods: {
    findYearList() {
      let yearList = [];
      let startingYear = 2021;
      let currentYear = moment().year();
      while (currentYear >= startingYear) {
        yearList.push(startingYear);
        startingYear = startingYear + 1;
      }
      return yearList;
    },
    setDeliveryStatusNoData(payload) {
      this.hasDeliveryStatusCountData = payload;
    },
    setDashboardData(dashboardData) {
      this.items = [];
      let totalCustomer = {
        title: "Active Customers",
        image: "mdi-account-group",
        count: dashboardData.totalCustomers,
      };
      let customersToday = {
        title: "Customers Today",
        image: "mdi-account-group",
        count: dashboardData.customersToday,
      };
      let salesOrderToday = {
        title: "Sales Order Today",
        image: "mdi-file-document",

        count: dashboardData.salesOrderToday,
      };
      let deliveriesToday = {
        title: "Deliveries Today",
        image: "mdi-truck",
        count: dashboardData.deliveriesToday,
      };
      let totalSales = {
        title: "Total Sales",
        image: "mdi-file-document",
        count: dashboardData.totalSalesOrder,
      };
      this.items.push(totalCustomer);
      this.items.push(customersToday);
      this.items.push(salesOrderToday);
      this.items.push(deliveriesToday);
      this.items.push(totalSales);
    },
    getRandomDataForTrends() {
      const arr = [];
      for (let i = 0; i < 12; i += 1) {
        arr.push(Math.random().toFixed(1) * 10);
      }
      return arr;
    },
    generatePieSeries() {
      let series = [];

      for (let i = 0; i < 4; i++) {
        let y = Math.floor(Math.random() * (500 - 100 + 100)) + 100;
        series.push(y);
      }
      return series;
    },
    getRandomInt(min, max) {
      let rand = min - 0.5 + Math.random() * (max - min + 1);
      return Math.round(rand);
    },
  },
  mounted() {
    setTimeout(() => {
      this.apexLoading = true;
    });
  },
};
</script>

<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
