<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-row class="mt-3 mt-sm-0">
      <h3 class="black--text text-h5 font-weight-bold ms-3 mb-6">Staff</h3>
    </v-row>

    <v-row align="center" class="mt-n4 mt-md-0">
      <v-col cols="12" sm="6" lg="4">
        <v-text-field
          placeholder="Name or Phone.."
          v-model="search"
          solo
          dense
          @keydown.enter="runSearch()"
        >
          <template v-slot:append>
            <v-icon
              v-if="search.length > 0"
              color="black"
              class="me-n2"
              @click="clearSearch()"
              >mdi-close</v-icon
            >
            <v-icon
              v-if="search.length == 0"
              color="black"
              class="me-n2"
              @click="runSearch()"
              >mdi-magnify</v-icon
            >
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="1" class="mb-4 mb-sm-0">
        <ManagerCreateForm v-if="hasRole(AdminUserPermissions.MANAGER_ADD)" />
      </v-col>
      <v-col class="mb-4 mb-sm-0">
        <AuditTrialLog class="ms-16 ms-md-4 mt-n6" auditType="MANAGER_LOG" />
      </v-col>
      <v-layout class="mt-1">
       <ExportManager
          :managerList="managerList"
          class="mr-5 mb-5 mt-n4 mt-md-0 me-3"
        /> 
      <router-link
        style="text-decoration: none"
        :to="{
          name: 'Permission'
        }"
        class="mx-auto"
        ><v-btn
          color="secondary"
          v-if="hasRole(AdminUserPermissions.PERMISSION_VIEW)"
          class="mb-5 mt-n4 mt-md-0 me-3 black--text text-capitalize"
        >
          Permission
        </v-btn>
      </router-link>
      </v-layout> 
    </v-row>

    <ManagersTable
      class="mt-n12"
      :managerList="managerList"
      :totalItemsCount="totalItemsCount"
      :loading="loading"
    ></ManagersTable>
  </v-container>
</template>

<script>
import ManagersTable from "../components/ManagersTable.vue";
import ManagerCreateForm from "../components/ManagerCreateForm.vue";
import AuditTrialLog from "@/audit-trial-log/components/AuditTrialLog.vue";
import ExportManager from "../components/ExportManager.vue";

export default {
  components: {
    ManagersTable,
    ManagerCreateForm,
    AuditTrialLog,
    ExportManager
  },
  mounted() {
    this.$root.$on("updateManager", () => {
      this.getAllManagers();
    });
  },
  data() {
    return {
      totalItemsCount: 25,
      search: "",
      managerList: [],
      loading: true,
      variables: {
        limit: 10000,
        skip: 0
      }
    };
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.MANAGER_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
    this.getAllManagers();
  },

  methods: {
    runSearch() {
      this.getAllManagers();
    },
    clearSearch() {
      this.search = "";
      this.getAllManagers();
    },
    getAllManagers() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getAllManagers.graphql"),
          variables: {
            skip: this.variables.skip,
            limit: this.variables.limit,
            searchKey: this.search
          }
        })
        .refetch({})
        .then(data => {
          this.loading = false;
          let managerList = data.data.getAllManagers.managers;
          this.managerList = managerList.map((items, index) => ({
            ...items,
            index: index + 1
          }));
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    }
  }
};
</script>
