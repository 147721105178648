<template>
  <!-- <v-container class="mt-n6 pa-0"> -->
  <v-dialog v-model="dialog" max-width="1000px" :fullscreen="$vuetify.breakpoint.smAndDown">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" v-if="hasRole(AdminUserPermissions.CUSTOMER_HEALTH_INFO_ADD)"
        class="black--text text-capitalize font-weight-bold" color="secondary">
        <v-icon class="me-1" small>mdi-plus</v-icon> Add
      </v-btn>
    </template>

    <v-form class="" @submit="createManager()" v-model="valid" ref="form">
      <v-card class="pb-10" color="">
        <v-card-title class="mx-0 px-14 secondary">
          <span class="text-h6 black--text"> Health Info</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider class="black"></v-divider>
        <v-container fluid class="px-0">
          <v-row class="mx-0 secondary mb-10 px-md-11">
            <v-col cols="12" md="2">
              <h4 class="text-body-2 black--text mb-1">Height</h4>
              <v-text-field v-model="height" min="0" suffix="cm" @keypress="formatHeight" type="number" outlined
                :rules="[requiredValidator('height')]" dense></v-text-field>
            </v-col>
            <v-col cols="6" md="2" class="d-flex text-center">
              <v-divider vertical class="me-10 "></v-divider>
              <div>
                <h4 class="text-body-2 black--text mb-1">Age</h4>
                {{ age }}
              </div>
            </v-col>
            <v-col cols="6" md="2" class="d-flex text-center">
              <v-divider vertical class="me-10 "></v-divider>
              <div>
                <h4 class="text-body-2 black--text mb-1">Gender</h4>
                {{ gender }}
              </div>
            </v-col>
          </v-row>
        </v-container>

        <v-container class="px-md-5 px-2  pt-0">
          <v-layout wrap class="mx-0 ">
            <v-flex xs12 class="  mb-2 mb-sm-8  ps-2 secondary mx-0">
              <h4 class="text-h6 black--text font-weight-bold">
                Body Composition Analysis
              </h4>
            </v-flex>
          </v-layout>
          <div class="px-4">
            <v-layout wrap class="mt-4 mt-sm-0">
              <v-flex xs12 md4>
                <h4 class="text-body-2 black--text">Total Body Water</h4>
              </v-flex>
              <v-flex xs12 md4>
                Protiens
              </v-flex>
              <v-flex xs12 sm8 md4>
                <v-text-field label="Body Water" v-model="totalBodyWater" @keypress="formatTotalBodyWater" min="0"
                  type="number" suffix="L" solo dense :rules="[requiredValidator('body water')]"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap class="mt-4 mt-sm-0">
              <v-flex xs12 md4>
                <h4 class="text-body-2 black--text">
                  Total Building Muscle
                </h4>
              </v-flex>
              <v-flex xs12 sm8 md4>
                Protiens
              </v-flex>
              <v-flex xs12 sm8 md4>
                <v-text-field label=" Building Muscle" v-model="totalBuildingMuscle"
                  @keypress="formatTotalBuildingMuscle" type="number" min="0" suffix="Kg" solo
                  :rules="[requiredValidator('building muscle')]" dense>
                </v-text-field>
              </v-flex>
            </v-layout>

            <v-layout wrap class="mt-4 mt-sm-0">
              <v-flex xs12 md4>
                <h4 class="text-body-2 black--text">
                  Total Strengthening Bones
                </h4>
              </v-flex>
              <v-flex xs12 sm8 md4>
                Minerals
              </v-flex>
              <v-flex xs12 sm8 md4>
                <v-text-field label=" Strengthening Bones" v-model="totalStrengtheningBones"
                  @keypress="formatTotalStrengtheningBones" type="number" solo
                  :rules="[requiredValidator('strengthening bones')]" min="0" suffix="Kg" dense></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout wrap class="mt-4 mt-sm-0">
              <v-flex xs12 md4>
                <h4 class="text-body-2 black--text">
                  Total Storing Excess Energy
                </h4>
              </v-flex>
              <v-flex xs12 sm8 md4>
                Boy Fat Mass
              </v-flex>
              <v-flex xs12 sm8 md4>
                <v-text-field label=" Excess Energy" v-model="totalStoringExcessEnergy"
                  @keypress="formatTotalStoringExcessEnergy" min="0" type="number" suffix="Kg" solo
                  :rules="[requiredValidator('excess energy')]" dense></v-text-field>
              </v-flex>
            </v-layout>
            <v-divider class="mb-4"></v-divider>
            <v-layout wrap class="mt-4 mt-sm-0">
              <v-flex xs12 md4>
                <h4 class="text-body-2 black--text">Sum of the above</h4>
              </v-flex>
              <v-flex xs12 sm8 md4>
                Weight
              </v-flex>
              <v-flex xs12 sm8 md4>
                <v-text-field label=" Sum" v-model="sum" @keypress="formatSum" type="number" suffix="Kg" min="0" solo
                  dense></v-text-field>
              </v-flex>
            </v-layout>
            <v-divider class="mb-4"></v-divider>
            <v-layout wrap>
              <v-flex xs12 class="mt-n2 mt-sm-2 mb-2 mb-sm-8">
                <h4 class="text-h6 black--text font-weight-bold">
                  Notes
                </h4>
              </v-flex>
            </v-layout>
            <v-layout wrap class="mt-4 mt-sm-0">
              <v-textarea outlined name="input-7-4" v-model="notes"></v-textarea>
            </v-layout>
          </div>
        </v-container>
        <v-row class="mx-0 px-md-4">
          <v-col cols="12" md="6">
            <div class="mt-10 grey2 ">
              <v-layout wrap class="secondary my-6 px-4">
                <v-flex xs12 class="">
                  <h4 class="text-h6 black--text font-weight-bold">
                    Muscle Fat Analysis
                  </h4>
                </v-flex>
              </v-layout>
              <div class="px-4">
                <v-layout wrap class="mt-4 mt-sm-0">
                  <v-flex xs12 md5>
                    <h4 class="text-body-2 black--text">Weight</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md6>
                    <v-text-field label="weight" v-model="weight" @keypress="formatWeight" type="number" min="0"
                      suffix="Kg" solo :rules="[requiredValidator('weight')]" dense></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout wrap class="mt-4 mt-sm-0">
                  <v-flex xs12 md5>
                    <h4 class="text-body-2 black--text">SMM</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md6>
                    <v-text-field label="SMM" v-model="smm" @keypress="formatSmm" type="number" solo min="0" suffix="Kg"
                      :rules="[requiredValidator('smm')]" dense></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout wrap class="mt-4 mt-sm-0">
                  <v-flex xs12 md5>
                    <h4 class="text-body-2 black--text me-2">
                      Body Fat Mass
                    </h4>
                  </v-flex>
                  <v-flex xs12 sm8 md6>
                    <v-text-field label="Body Fat Mass" v-model="bodyFatMass" @keypress="formatBodyFatMass"
                      type="number" min="0" suffix="Kg" solo :rules="[requiredValidator('fat mass')]" dense>
                    </v-text-field>
                  </v-flex>
                </v-layout>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="mt-10 grey2 ">
              <v-layout wrap class="secondary my-6 px-4">
                <v-flex xs12 class="">
                  <h4 class="text-h6 black--text font-weight-bold">
                    Obesity Analysis
                  </h4>
                </v-flex>
              </v-layout>
              <div class="px-4">
                <v-layout wrap class="mt-4 mt-sm-0">
                  <v-flex xs12 md5>
                    <h4 class="text-body-2 black--text">BMI</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md6>
                    <v-text-field label="BMI" v-model="bmi" @keypress="formatBmi" type="number" suffix="Kg/(m)2" min="0"
                      solo :rules="[requiredValidator('bmi')]" dense></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout wrap class="mt-4 mt-sm-0">
                  <v-flex xs12 md5>
                    <h4 class="text-body-2 black--text">PBF</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md6>
                    <v-text-field label="PBF" v-model="pbf" @keypress="formatPbf" type="number" suffix="%" min="0" solo
                      :rules="[requiredValidator('pbf')]" dense></v-text-field>
                  </v-flex>
                </v-layout>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-card-actions class="mt-n4 mt-sm-0 mb-sm-n5 ">
          <v-spacer></v-spacer>
          <v-btn color="secondary" class="black--text text-capitalize" :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
            :loading="btnloading" @click="createManager" :disabled="!valid">Add</v-btn>
        </v-card-actions>

        <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackbarColor">{{ text }}</v-snackbar>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { requiredValidator, integer } from "../../../core/methods/validations";
export default {
  props: {
    customer: {
      required: true
    }
  },
  created() {
    this.gender = this.customerInfo.user.gender;
    this.dob = this.customerInfo.user.dob;
    this.age = this.getAge(this.dob);
    this.weight = this.customerInfo.weight;
    this.height = this.customerInfo.height;
    console.log("custoemrInfo", this.customerInfo);
  },

  data() {
    return {
      height: "",
      age: "",
      gender: "",
      totalBodyWater: "",
      totalBuildingMuscle: "",
      totalStrengtheningBones: "",
      totalStoringExcessEnergy: "",
      sum: "",
      weight: "",
      smm: "",
      bodyFatMass: "",
      bmi: "",
      pbf: "",
      notes: "",
      customerInfo: this.customer,
      dob: "",

      genderList: [
        { text: "MALE", value: "male" },
        { text: "FEMALE", value: "female" }
      ],
      requiredValidator: requiredValidator,
      integer: integer,
      snackbar: false,
      snackbarColor: "",
      text: "",
      valid: true,
      btnloading: false,
      dialog: false
    };
  },
  //   mounted() {
  //     this.$root.$on("getHealthInfoByCustomerId", () => {
  //       this.loading = true;
  //       this.$apollo.queries.customers.refetch({
  //         variables: this.variables
  //       });
  //     });
  //   },

  methods: {
    createManager() {
      this.btnloading = true;
      let CreateHealthInfoInput = {
        customer: this.$route.query.customerId,
        height: parseFloat(this.height),
        age: parseInt(this.age),
        gender: this.gender,
        notes: this.notes,
        bodyCompositionAnalysis: {
          totalBodyWater: parseFloat(this.totalBodyWater),
          totalBuildingMuscle: parseFloat(this.totalBuildingMuscle),
          totalStrengtheningBones: parseFloat(this.totalStrengtheningBones),
          totalStoringExcessEnergy: parseFloat(this.totalStoringExcessEnergy),
          sum: parseFloat(this.sum)
        },
        muscleFatAnalysis: {
          weight: parseFloat(this.weight),
          smm: parseFloat(this.smm),
          bodyFatMass: parseFloat(this.bodyFatMass)
        },
        obesityAnalysis: {
          bmi: parseFloat(this.bmi),
          pbf: parseFloat(this.pbf)
        }
      };

      this.$apollo
        .mutate({
          mutation: require("../../api/createHealthInfo.graphql"),
          variables: {
            input: CreateHealthInfoInput
          },
          update: () => {
            this.showDialog = false;
          }
        })
        .then(() => {
          this.$root.$emit("updateHelthInfo");
          this.close();
          this.$refs.form.reset();
          this.text = "Customer Added";
          this.snackbarColor = "success";
          this.snackbar = true;
          this.btnloading = false;
        })
        .catch(error => {
          this.snackbarColor = "error";
          this.snackbar = true;
          this.text = "Some Error";
          if (error.graphQLErrors != null) {
            window.scrollTo(0, 0);
            this.$refs.form.$el.scrollIntoView({ behavior: "smooth" });
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
    close() {
      this.dialog = false;
    },

    formatTotalBodyWater: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      // console.log("keyCode ",evt.keyCode)
      // console.log("event ",evt)
      if (
        (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) ||
        this.totalBodyWater.indexOf(".") != -1
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    formatHeight: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) ||
        this.height.indexOf(".") != -1
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    formatTotalBuildingMuscle: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) ||
        this.totalBuildingMuscle.indexOf(".") != -1
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    formatTotalStrengtheningBones: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) ||
        this.totalStrengtheningBones.indexOf(".") != -1
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    formatTotalStoringExcessEnergy: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) ||
        this.totalStoringExcessEnergy.indexOf(".") != -1
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    formatSum: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) ||
        this.sum.indexOf(".") != -1
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    formatWeight: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) ||
        this.weight.indexOf(".") != -1
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    formatSmm: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) ||
        this.smm.indexOf(".") != -1
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    formatBodyFatMass: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) ||
        this.bodyFatMass.indexOf(".") != -1
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    formatBmi: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) ||
        this.bmi.indexOf(".") != -1
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    formatPbf: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) ||
        this.pbf.indexOf(".") != -1
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getAge() {
      console.log("Inside get Age");
      if (this.dob) {
        let dateofbirth = new Date(parseInt(this.dob));
        var ageDifMs = Date.now() - dateofbirth.getTime();
        var ageDate = new Date(ageDifMs);
        let age = Math.abs(ageDate.getUTCFullYear() - 1970);
        age = age > 0 ? age : 1;
        return age;
      } else return "";
    }
  }
};
</script>
