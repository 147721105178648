<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class="black--text text-capitalize font-weight-bold"
          color="secondary"
        >
          <v-icon class="me-1" small>mdi-plus</v-icon> Add
        </v-btn>
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 black--text">Add Block</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form
          class="pa-sm-8 mt-n6"
          @submit="createBlock()"
          v-model="valid"
          ref="form"
        >
          <v-card-text>
            <v-container>
              <v-layout class="mt-3 mt-sm-0" wrap>
                <v-flex xs12 sm2 md2 class="text-body-2 black--text">
                  Select Area
                </v-flex>
                <v-flex xs12 sm7 md5>
                  <v-autocomplete
                    item-text="nameEn"
                    v-model="area"
                    item-value="_id"
                    :items="areaList"
                    :rules="[requiredValidator('area ')]"
                    dense
                    attach
                    solo
                  >
                    <template slot="no-data">
                      <v-flex xs12 md12 class="mt-2 mb-2">
                        <h5 class="font-weight-medium ml-4">
                          Search for area...
                        </h5>
                      </v-flex>
                    </template>
                  </v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm2 md2 class="text-body-2 black--text">
                  Block
                </v-flex>
                <v-flex xs12 sm7 md5>
                  <v-text-field
                    v-model="block"
                    :rules="[requiredValidator('block')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout justify-center>
                <v-flex xs8 md2 class="mt-8"> </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions class="mb-sm-n4 mt-n16">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              class="black--text text-capitalize"
              :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
              :loading="btnloading"
              @click="createBlock"
              :disabled="!valid"
              >Add</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
      text
    }}</v-snackbar>
    <v-snackbar
      v-model="snackbarError"
      timeout="3000"
      right
      top
      color="error"
      >{{ text }}</v-snackbar
    >
  </div>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
export default {
  data() {
    return {
      requiredValidator: requiredValidator,
      dialog: false,
      snackbarError: false,
      btnloading: false,
      addedArea: [],
      areaList: [],
      block: "",
      area: "",
      snackbar: false,
      text: "",
      valid: true
    };
  },

  apollo: {
    getAllAreas() {
      return {
        query: require("../api/getAllAreas.graphql"),
        result({ data, loading }) {
          this.loading = loading;
          this.areaList = data.getAllAreas.areas;
          this.totalItemsCount = data.getAllAreas.totalCount;
        }
      };
    }
  },
  methods: {
    createBlock() {
      console.log(this.area, this.block);
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/createBlock.graphql"),
          variables: {
            area: this.area,
            block: this.block
          },
          update: () => {
            try {
              this.$root.$emit("updateBlock");
              this.text = "Block Added";
              this.snackbar = true;
            } catch (e) {
              console.log(e);
              console.log("Something bad happend");
            }
            this.$refs.form.reset();
            this.btnloading = false;
            this.dialog = false;
          }
        })
        .catch(error => {
          this.btnloading = false;
          this.text = "Block Already exist.";
          this.snackbarError = true;

          console.log(error);
        });
    },

    close() {
      this.dialog = false;
    }
  }
};
</script>
